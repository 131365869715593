import React from 'react';
import {Space, Divider} from 'antd';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {FlexContainer} from '../../common/Styled';
import styled, {css} from 'styled-components';
import {APP_NAME, appAuthUrl, domainAuthUrl} from '../../../services/const';

export default withTranslation()(AuthFooter);

export interface IAuthFooterProps extends WithTranslation {
  isLoginPage?: boolean;
}

const Container = styled(FlexContainer)`
  margin-top: 20px;
`;

const styleLink = css`
  font-size: 16px;
  color: ${({theme}) => theme.colors.buttonPrimaryColor};

  @media (max-width: 475px) {
    font-size: 13px;
  }

  &:hover {
    color: ${({theme}) => theme.colors.buttonSecondaryColor};
  }
`;

const StyledLink = styled(Link)`
  ${styleLink}
`;

const StyledLinkTo = styled.a`
  ${styleLink}
`;

const StyledDivider = styled(Divider)`
  border-color: ${({theme}) => theme.colors.disable};
  width: 100%;
`;

function AuthFooter({t, isLoginPage = false}: IAuthFooterProps): JSX.Element {
  return (
    <Container justifyContent="center" alignItems="center">
      <Space>
        {isLoginPage ? (
          <>
            <StyledLink to={'/auth/forgot-password'}>
              {`${t('Forgot password')}?`}
            </StyledLink>
            <StyledDivider type="vertical" />
            <StyledLinkTo href={domainAuthUrl} rel="noreferrer" target="_blank">
              {t('Signup')}
            </StyledLinkTo>
            <StyledDivider type="vertical" />
            <StyledLinkTo href={appAuthUrl} rel="noreferrer">
              {`${t('Login to')} ${APP_NAME}`}
            </StyledLinkTo>
          </>
        ) : (
          <>
            <StyledDivider type="vertical" />
            <StyledLink to={'/auth/login'}>{t('Login')}</StyledLink>
            <StyledDivider type="vertical" />
          </>
        )}
      </Space>
    </Container>
  );
}
