import React from 'react';
import {FullHeightLayout} from '../Styled';
import styled from 'styled-components';
import {Layout} from 'antd';
import HeaderWithoutLayout from './HeaderWithoutLayout';
import FooterWithoutLayout from './FooterWithoutLayout';

export interface IAuthLayoutProps {
  children: React.ReactNode;
}

const StyledContent = styled(Layout.Content)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${({theme}) => theme.colors.app};
`;

export default function AuthLayout({children}: IAuthLayoutProps): JSX.Element {
  return (
    <FullHeightLayout data-testid="auth-layout">
      <HeaderWithoutLayout />
      <StyledContent>{children || <span>no content</span>}</StyledContent>
      <FooterWithoutLayout />
    </FullHeightLayout>
  );
}
