import React from 'react';
import {Layout} from 'antd';
import classNames from 'classnames';
import styled from 'styled-components';

import './Content.less';

export interface IContentProps {
  children: React.ReactNode;
}

const StyledContent = styled(Layout.Content)`
  padding: 0 25px;
`;

export default function Content({children}: IContentProps): JSX.Element {
  return (
    <StyledContent data-testid="layout-content" className="content">
      <div className={classNames('content__wrap')}>{children}</div>
    </StyledContent>
  );
}
