import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './container/App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import configureStore from './store';
import {googleAnalyticsTrackingId} from './services/const';
import ReactGA from 'react-ga';
import {createBrowserHistory} from 'history';

import 'moment/locale/uk';
import 'moment/locale/en-ca';
import 'moment/locale/ru';

import './i18n';

ReactGA.initialize(googleAnalyticsTrackingId);

const history = createBrowserHistory();

history.listen((location) => {
  ReactGA.set({page: window.location.pathname});
  ReactGA.pageview(window.location.pathname);
});

const store = configureStore();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
