export interface ILoginDTOProps {
  identifier?: string;
  password?: string;
  invite_id?: string;
}

export class LoginDTO {
  public identifier: string;
  public password: string;
  public invite_id?: string;

  constructor(props?: ILoginDTOProps) {
    this.identifier = props?.identifier || '';
    this.password = props?.password || '';
    this.invite_id = props?.invite_id || '';
  }
}
