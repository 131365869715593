export enum EPlanTypeIdentifier {
  TRIAL = 'trial',
  STARTER = 'starter',
  PROFESSIONAL = 'prof',
  BUSINESS = 'business',
}

export enum EPlanPeriod {
  DAY = 'day',
  MONTH = 'month',
}

export enum EPlanStatuses {
  EXPIRED = -2,
  DISABLED = -1,
  PROCESSING = 0,
  ACTIVE = 1,
  PAID_IN_ADVANCE = 2,
}

export enum EPlanLimitation {
  COMPANY = 'company_limitation',
  SERVICE = 'service_limitation',
  EMPLOYEE = 'employee_limitation',
  CLIENT = 'client_limitation',
}

export interface IPlanRule {
  id: EPlanLimitation;
  limit: number;
}

export const PLANS_STARTER = EPlanTypeIdentifier.STARTER;
export const PLANS_BUSINESS = EPlanTypeIdentifier.BUSINESS;
export const PLANS_PROFESSIONAL = EPlanTypeIdentifier.PROFESSIONAL;
export const PLANS_TRIAL = EPlanTypeIdentifier.TRIAL;

export const PLAN_DURATION_DAY = EPlanPeriod.DAY;
export const PLAN_DURATION_MONTH = EPlanPeriod.MONTH;

export const SUBSCRIPTION_STATUS_ACTIVE = EPlanStatuses.ACTIVE;
export const SUBSCRIPTION_STATUS_EXPIRED = EPlanStatuses.EXPIRED;
export const SUBSCRIPTION_STATUS_DISABLED = EPlanStatuses.DISABLED;
export const SUBSCRIPTION_STATUS_PROCESSING = EPlanStatuses.PROCESSING;
export const SUBSCRIPTION_STATUS_PAID_IN_ADVANCE =
  EPlanStatuses.PAID_IN_ADVANCE;

export const PLAN_STATUS_ACTIVE = SUBSCRIPTION_STATUS_ACTIVE;
export const PLAN_STATUS_PROCESSING = SUBSCRIPTION_STATUS_PROCESSING;
export const PLAN_STATUS_BLOCKED = SUBSCRIPTION_STATUS_DISABLED;
export const PLAN_STATUS_EXPIRED = SUBSCRIPTION_STATUS_EXPIRED;

interface ISystemSubscription {
  id: EPlanTypeIdentifier;
  title: string;
  price: number;
  duration: number;
  period: EPlanPeriod;
  defaultStatus: EPlanStatuses;
  rules: IPlanRule[];
}

export const systemSubscriptions: ISystemSubscription[] = [
  {
    id: PLANS_TRIAL,
    title: 'Trial',
    price: 0,
    duration: 35,
    period: EPlanPeriod.DAY,
    defaultStatus: EPlanStatuses.ACTIVE,
    rules: [
      {id: EPlanLimitation.COMPANY, limit: 1},
      {id: EPlanLimitation.EMPLOYEE, limit: 10},
      {id: EPlanLimitation.SERVICE, limit: 200},
      {id: EPlanLimitation.CLIENT, limit: 1000},
    ],
  },
  {
    id: PLANS_STARTER,
    title: 'Starter',
    price: 300,
    duration: 1,
    period: EPlanPeriod.MONTH,
    defaultStatus: EPlanStatuses.PROCESSING,
    rules: [
      {id: EPlanLimitation.COMPANY, limit: 1},
      {id: EPlanLimitation.EMPLOYEE, limit: 5},
      {id: EPlanLimitation.SERVICE, limit: 500},
      {id: EPlanLimitation.CLIENT, limit: 5000},
    ],
  },
  {
    id: PLANS_PROFESSIONAL,
    title: 'Professional',
    price: 450,
    duration: 1,
    period: EPlanPeriod.MONTH,
    defaultStatus: EPlanStatuses.PROCESSING,
    rules: [
      {id: EPlanLimitation.COMPANY, limit: 1},
      {id: EPlanLimitation.EMPLOYEE, limit: 30},
      {id: EPlanLimitation.SERVICE, limit: 1000},
      {id: EPlanLimitation.CLIENT, limit: 50000},
    ],
  },
  {
    id: PLANS_BUSINESS,
    title: 'Business',
    price: 800,
    duration: 1,
    period: EPlanPeriod.MONTH,
    defaultStatus: EPlanStatuses.PROCESSING,
    rules: [
      {id: EPlanLimitation.COMPANY, limit: 3},
      {id: EPlanLimitation.EMPLOYEE, limit: 100},
      {id: EPlanLimitation.SERVICE, limit: 20000},
      {id: EPlanLimitation.CLIENT, limit: 50000},
    ],
  },
];
