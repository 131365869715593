import React, {forwardRef} from 'react';
import {DatePicker as AntDatePicker} from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import ua from 'antd/es/date-picker/locale/uk_UA';
import en from 'antd/es/date-picker/locale/en_US';
import ru from 'antd/es/date-picker/locale/ru_RU';

export interface IDatePickerProps {}

const locale: any = {
  uk: ua,
  en,
  ru,
};

const StyledAntDatePicker = styled(AntDatePicker)`
  height: 42px;
  border-color: rgb(236, 236, 236);
`;

export default forwardRef(function DatePicker(
  {placeholder, disabled, ...rest}: IDatePickerProps & any,
  ref: any,
): JSX.Element {
  return (
    <StyledAntDatePicker
      ref={ref}
      disabled={disabled}
      placeholder={disabled ? '' : placeholder}
      locale={locale[moment.locale()] || locale.en}
      {...rest}
    />
  );
});
