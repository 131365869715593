import React, {useCallback} from 'react';
import {Menu} from 'antd';
import {Link, withRouter, RouteComponentProps} from 'react-router-dom';
import {withTranslation, WithTranslation} from 'react-i18next';
import styled from 'styled-components';
import {
  getRootKey,
  checkIsAdminRoute,
  isFunction,
} from '../../../services/helpers';
import {useActiveKey} from '../hooks';
import {Routes, Menu_Title} from '../../../services/types';
import {Popover} from 'antd';
import {UserOutlined, FileDoneOutlined, HomeOutlined} from '@ant-design/icons';

export default withTranslation()(withRouter(MainMenu));

export interface IMainMenuProps
  extends WithTranslation,
    RouteComponentProps<any> {
  appTitle: string;
  adminRoutes: Routes[];
  handleCloseMenu?: () => void;
}

export type MenuItem = {
  key: string;
  title: string;
  icon: React.ReactNode;
  className?: string;
  items?: MenuItem[];
  withPopover?: boolean;
};

export type MenuGroup = {
  groupTitle: string;
  items: MenuItem[];
};

interface IMenuGroupProps {
  path: string;
  t: any;
  adminRoutes: Routes[];
}

const menuGroups = ({path, t, adminRoutes}: IMenuGroupProps): MenuGroup[] => [
  {
    groupTitle: t('Navigation'),
    items: [
      {
        key: `/${path}/${Routes.dashboard}`,
        title: t(Menu_Title.dashboard),
        icon: <HomeOutlined />,
      },
      {
        key: `/${path}/${Routes.subscription}`,
        title: t(Menu_Title.subscription),
        icon: <FileDoneOutlined />,
      },
      {
        key: `/${path}/${Routes.profile}`,
        title: t(Menu_Title.profile),
        icon: <UserOutlined />,
      },
    ],
  },
];

const StyledMenu = styled(Menu)`
  background-color: #363a41;
  border-right: 0;
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  & * {
    color: ${({theme}) => theme.colors.app};
  }

  &:hover {
    background: ${({theme}) => theme.colors.mainBackground};

    & * {
      color: ${({theme}) => theme.colors.app};
    }
  }

  &&.ant-menu-item-selected {
    background: ${({theme}) => theme.colors.mainBackground};

    & * {
      color: ${({theme}) => theme.colors.app};
    }
  }
`;

const StyledSubMenu = styled(Menu.SubMenu)`
  & .ant-menu-sub {
    background-color: ${({theme}) => theme.colors.third};
  }

  & * {
    color: ${({theme}) => theme.colors.app};
  }

  & *::before {
    color: ${({theme}) => theme.colors.app};
  }

  & *::after {
    color: ${({theme}) => theme.colors.app};
  }
`;

const MenuItemGroup = styled(Menu.ItemGroup)`
  & * {
    color: ${({theme}) => theme.colors.app};
  }
`;

function MainMenu({
  location,
  history,
  t,
  adminRoutes,
  handleCloseMenu,
}: IMainMenuProps) {
  const [activeKey] = useActiveKey(location.pathname, history);
  const rootKey = getRootKey(location.pathname);

  const handleCloseMenuModal = useCallback(() => {
    if (isFunction(handleCloseMenu)) {
      handleCloseMenu();
    }
  }, [handleCloseMenu]);

  const getMenuNestedItem = (items: MenuItem[]): any => {
    return items.map(
      ({key, icon, title, className, items: nestedItem, withPopover}) => {
        if (Array.isArray(nestedItem)) {
          return (
            <StyledSubMenu
              key={key}
              icon={icon}
              title={title}
              className="main-menu__submenu">
              {getMenuNestedItem(nestedItem)}
            </StyledSubMenu>
          );
        }
        return (
          <StyledMenuItem key={key} icon={icon}>
            {withPopover ? (
              <Popover title={title}>
                <Link
                  to={key}
                  className={className}
                  onClick={handleCloseMenuModal}>
                  <span>{title}</span>
                </Link>
              </Popover>
            ) : (
              <Link
                to={key}
                className={className}
                onClick={handleCloseMenuModal}>
                <span>{title}</span>
              </Link>
            )}
          </StyledMenuItem>
        );
      },
    );
  };

  return (
    <StyledMenu
      multiple
      theme="light"
      mode="inline"
      defaultSelectedKeys={[activeKey]}
      selectedKeys={[activeKey]}>
      {menuGroups({path: rootKey, t, adminRoutes}).map(
        ({groupTitle, items}) => (
          <MenuItemGroup key={groupTitle} title={groupTitle}>
            {getMenuNestedItem(items)}
          </MenuItemGroup>
        ),
      )}
    </StyledMenu>
  );
}
