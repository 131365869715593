import React from 'react';
import {Divider, Row} from 'antd';
import {Text} from '../../common/Format';
import styled from 'styled-components';

export interface IProfileFormGroupProps {
  title: string;
  children: React.ReactNode;
}

const StyledText = styled(Text)`
  font-size: 16px;
  margin-left: 10px;
  font-weight: 500;
`;

const StyledRow = styled(Row)`
  width: 100%;
  margin: 0 !important;
`;

const StyledDivider = styled(Divider)`
  margin: 10px 0;
`;

export default function ProfileFormGroup({
  title = '',
  children,
}: IProfileFormGroupProps): JSX.Element {
  return (
    <StyledRow gutter={20}>
      <StyledText primary>{title}</StyledText>
      <StyledDivider />
      {children}
    </StyledRow>
  );
}
