import * as React from 'react';
import {Dispatch, SetStateAction} from 'react';
import {
  filter,
  isNotEqualById,
  every,
  prop,
  FunctionArgs,
} from '../../../services/helpers';
import {List} from 'immutable';
import {ILiteralObj} from '../../../services/types';

export interface IUseStateEntityListProps<T> extends ILiteralObj {
  entityList: List<T> | null;
  initialState?: List<T> | null;
}

export interface IUseStateEntityListReturnedType<T> {
  entityList: List<T> | null;
  handleDelete: (id: string[], prop?: string) => void;
  handleCreate: (item: T, insertInStart?: boolean) => void;
  handleUpdate: (item: T) => void;
  setEntityList: Dispatch<SetStateAction<List<T> | null>>;
  loading: boolean;
}

export default function useStateEntityList<T>({
  entityList: data,
  initialState = null,
}: IUseStateEntityListProps<T>): IUseStateEntityListReturnedType<T> {
  const [entityList, setEntityList] = React.useState<List<T> | null>(
    initialState,
  );
  const [loading, setLoading] = React.useState<boolean>(true);

  const handleDelete = React.useCallback(
    (ids: string[], key: string = 'uuid'): void => {
      setEntityList(
        (prevState: List<T> | null): List<T> =>
          filter<[FunctionArgs<T, boolean>, any], List<T>>(
            (i: T) => every(isNotEqualById(prop(key, i)), ids),
            prevState || List([]),
          ),
      );
    },
    [],
  );

  const handleCreate = React.useCallback(
    (item: T, insertInStart: boolean = false): void => {
      setEntityList((prevState: List<T> | null): List<T> => {
        const prevStateEntityList = prevState || List([]);
        return insertInStart
          ? prevStateEntityList.set(0, item)
          : prevStateEntityList?.push(item);
      });
    },
    [],
  );

  const handleUpdate = React.useCallback(
    (item: any, identifier = 'uuid'): void => {
      setEntityList((prevState: List<T> | null): List<T> => {
        const prevStateEntityList = prevState || List([]);
        const index = prevStateEntityList.indexOf(item[identifier]);

        if (index !== -1) {
          return prevStateEntityList.update(index, item);
        }

        return prevStateEntityList;
      });
    },
    [],
  );

  const addEntities = React.useCallback((entities: List<T>) => {
    setEntityList((prevState) => {
      if (prevState) {
        return prevState.concat(entities);
      }
      return prevState;
    });
  }, []);

  React.useEffect(() => {
    let didCancel = false;

    if (data && !didCancel) {
      setEntityList(data);
      setLoading(false);
    }
    return () => {
      didCancel = true;
    };
  }, [data]);

  return {
    entityList,
    setEntityList,
    handleDelete,
    handleCreate,
    handleUpdate,
    loading,
  };
}
