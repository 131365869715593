import React from 'react';
import AuthHeader, {IAuthHeaderProps} from './AuthHeader';
import {WithTranslation} from 'react-i18next';
import styled from 'styled-components';
import AuthFooter, {IAuthFooterProps} from './AuthFooter';

export interface IAuthLayoutProps
  extends Omit<IAuthHeaderProps, keyof WithTranslation>,
    Pick<IAuthFooterProps, 'isLoginPage'> {
  children: React.ReactNode;
}

const Container = styled.div`
  padding: 20px 0;
  width: 50vw;

  @media (max-width: 1200px) {
    width: 70vw;
  }

  @media (max-width: 767px) {
    width: 80vw;
  }

  @media (max-width: 475px) {
    width: 90vw;
  }
`;

export default function AuthLayout({
  title,
  children,
  isLoginPage,
}: IAuthLayoutProps): JSX.Element {
  return (
    <Container>
      <AuthHeader title={title} />
      {children}
      <AuthFooter isLoginPage={isLoginPage} />
    </Container>
  );
}
