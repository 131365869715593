import React, {useCallback, useMemo, useState, useEffect} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Col, Radio, Form, Row} from 'antd';
import {DefaultForm, IDefaultFormProps} from '../../common/General';
import {PhoneInput, Input, DatePicker} from '../../common/DataEntry';
import {UserFormDTO} from '../../../struture';
import {ProfileFormActions, ProfileFormGroup, ProfileFormHeader} from '../Show';
import {FormInstance} from 'antd/lib/form';
import {GENDER, ApiError} from '../../../services/types';
import styled from 'styled-components';
import {isFunction} from '../../../services/helpers';
import {useNotify} from '../../common/hooks';

const tabletScreenMediaQuery = matchMedia('(max-width: 590px)');

export default withTranslation()(EmployeeForm);

export interface IUserFormProps
  extends WithTranslation,
    Omit<
      IDefaultFormProps<UserFormDTO, UserFormDTO>,
      'children' | 'initialValues' | 'additionalValuesRequest' | 'showFooter'
    > {
  loading?: boolean;
  profile: UserFormDTO;
  onCancel?: () => void;
  onSuccess: (value: UserFormDTO) => Promise<void>;
  onError?: () => Promise<void>;
}

const StyledRow = styled(Row)`
  background-color: ${({theme}) => theme.colors.app};
  border-radius: ${({theme}) => theme.border.baseBorderRadius};
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

/**
 * @desc Створенння співробітника
 * */
function EmployeeForm({
  t,
  loading,
  profile,
  onSuccess,
  ...rest
}: IUserFormProps) {
  const {notifyMessage, notifyErrorMessage} = useNotify();

  const [isTabletScreen, setIsTabletScreen] = useState<boolean>(
    tabletScreenMediaQuery?.matches,
  );

  const [editMode, setEditMode] = useState(false);

  const resizeTabletScreen = useCallback(
    ({matches}: MediaQueryListEvent): void => {
      setIsTabletScreen(matches);
    },
    [],
  );

  const additionalValues = useMemo(
    () => ({
      uuid: profile?.id,
    }),
    [profile?.id],
  );

  const handleOnEditMode = useCallback(() => {
    setEditMode(true);
  }, []);

  const handleOffEditMode = useCallback(
    ({resetFields}: Pick<FormInstance, 'resetFields'>) => {
      setEditMode(false);
      resetFields();
    },
    [],
  );

  const handleSuccess = useCallback(
    async (value: UserFormDTO) => {
      if (isFunction(onSuccess)) {
        await onSuccess(value);
      }
      setEditMode(false);
    },
    [onSuccess],
  );

  useEffect(() => {
    tabletScreenMediaQuery.addEventListener('change', resizeTabletScreen);

    return () => {
      tabletScreenMediaQuery.removeEventListener('change', resizeTabletScreen);
    };
  }, [resizeTabletScreen]);

  const notifyError = useCallback(
    (error: ApiError, editMode?: boolean): void => {
      if (editMode) {
        notifyErrorMessage(
          'An error occurred during profile creation',
          error.message,
        );
      } else {
        notifyErrorMessage(
          'An error occurred while editing a profile',
          error.message,
        );
      }
    },
    [notifyErrorMessage],
  );

  const notifySuccess = useCallback(
    (editMode?: boolean): void => {
      if (editMode) {
        notifyMessage('User edited successfully');
      } else {
        notifyMessage('User created successfully');
      }
    },
    [notifyMessage],
  );

  return (
    <DefaultForm<UserFormDTO, UserFormDTO>
      initialValues={{
        ...profile,
      }}
      editMode={editMode}
      notifySuccess={notifySuccess}
      notifyError={notifyError}
      showFooter={false}
      additionalValuesRequest={additionalValues}
      onSuccess={handleSuccess}
      {...rest}>
      {({loadingSubmit, submit, setFieldsValue, resetFields}) => (
        <>
          <ProfileFormActions
            onCancel={() => handleOffEditMode({resetFields})}
            onEdit={handleOnEditMode}
            submit={submit}
            editMode={editMode}
            loading={loadingSubmit}
          />

          <StyledRow gutter={20}>
            <ProfileFormHeader title={t('User')} />

            <ProfileFormGroup title={t('Email address')}>
              <Col span={24}>
                <Form.Item
                  label={t('Email')}
                  tooltip={t('Your email')}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t('Email must be specified'),
                    },
                  ]}>
                  <Input
                    data-testid="user__input--email"
                    disabled={loading || loadingSubmit || !editMode}
                    placeholder={t('Enter email')}
                  />
                </Form.Item>
              </Col>
            </ProfileFormGroup>

            <ProfileFormGroup title={t('Personal details')}>
              <Col span={isTabletScreen ? 24 : 8}>
                <Form.Item
                  tooltip={t('Your name')}
                  label={t('Name')}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('Name must be specified'),
                    },
                  ]}>
                  <Input
                    disabled={loading || loadingSubmit || !editMode}
                    data-testid="user__input--first-name"
                    placeholder={t('Enter your name')}
                  />
                </Form.Item>
              </Col>
              <Col span={isTabletScreen ? 24 : 8}>
                <Form.Item
                  tooltip={t('Your middle name')}
                  label={t('Middle name')}
                  name="middlename"
                  rules={[
                    {
                      required: true,
                      message: t('Middle name must be specified'),
                    },
                  ]}>
                  <Input
                    disabled={loading || loadingSubmit || !editMode}
                    data-testid="user__input--middle-name"
                    placeholder={t('Enter your middle name')}
                  />
                </Form.Item>
              </Col>
              <Col span={isTabletScreen ? 24 : 8}>
                <Form.Item
                  tooltip={t('Surname')}
                  label={t('Your surname')}
                  name="surname"
                  rules={[
                    {
                      required: true,
                      message: t('Surname must be specified'),
                    },
                  ]}>
                  <Input
                    data-testid="user__input--last-name"
                    disabled={loading || loadingSubmit || !editMode}
                    placeholder={t('Enter your surname')}
                  />
                </Form.Item>
              </Col>
              <Col span={isTabletScreen ? 24 : 12}>
                <Form.Item
                  label={t('Phone')}
                  tooltip={t('Your phone')}
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: t('Phone must be specified'),
                    },
                  ]}>
                  <PhoneInput
                    dataTestid="user__input--phone"
                    placeholder={t('Enter your phone')}
                    loading={loading || loadingSubmit || !editMode}
                    onChange={(phone) => setFieldsValue({phone})}
                  />
                </Form.Item>
              </Col>

              <Col span={isTabletScreen ? 24 : 12}>
                <Form.Item
                  label={t('Birthday')}
                  name="birthday"
                  tooltip={t('Your birthday')}
                  rules={[
                    {
                      required: true,
                      message: t('Birthday must be specified'),
                    },
                  ]}>
                  <StyledDatePicker
                    data-testid="user__picker--birthday"
                    disabled={loading || loadingSubmit || !editMode}
                    placeholder={t('Enter your birthday')}
                  />
                </Form.Item>
              </Col>
              <Col span={isTabletScreen ? 24 : 12}>
                <Form.Item
                  label={t('Address')}
                  tooltip={t('Your address')}
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: t('Address must be specified'),
                    },
                  ]}>
                  <Input
                    data-testid="user__input--address"
                    disabled={loading || loadingSubmit || !editMode}
                    placeholder={t('Enter your address')}
                  />
                </Form.Item>
              </Col>
              <Col span={isTabletScreen ? 24 : 12}>
                <Form.Item label={t('Gender')} name="gender">
                  <Radio.Group
                    data-testid="user__radio--gender"
                    disabled={loading || loadingSubmit || !editMode}>
                    <Radio value={GENDER.MALE}>{t('Male')}</Radio>
                    <Radio value={GENDER.FEMALE}>{t('Female')}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </ProfileFormGroup>
          </StyledRow>
        </>
      )}
    </DefaultForm>
  );
}
