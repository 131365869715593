import {SubscriptionDTO, ISubscriptionDTOProps} from './SubscriptionDTO';
export interface ISubscriptionListDTOProps {
  subscriptions: ISubscriptionDTOProps[];
}

export class SubscriptionListDTO {
  public subscriptions: ISubscriptionDTOProps[];

  constructor({subscriptions = []}: ISubscriptionListDTOProps) {
    this.subscriptions = subscriptions.map(
      (subscription) => new SubscriptionDTO(subscription),
    );
  }
}
