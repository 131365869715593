import {Record} from 'immutable';
import {verificationHelpers as vHelper} from '../helpers';
import {company, verification} from '../constants';
import EmployeeList, {EmployeeListProps} from './EmployeeList';
import {CategoryProps} from './Category';
import GroupList, {GroupListProps} from './GroupList';
import CompanySettingList, {
  ICompanySettingListProps,
} from './CompanySettingList';

export interface CompanyProps extends Pick<GroupListProps, 'groups'> {
  id: string;
  uuid: string;
  title: string;
  phone: string;
  phone_checked: number;
  email: string;
  email_checked: number;
  description: string;
  cover_url: string;
  logo_url: string;
  qr_code_url: string;
  address: string;
  address_langitude: string;
  address_latitude: string;
  slogan: string;
  location_country: string;
  location_region: string;
  location_district: string;
  location_city: string;
  status: number;
  created_at: string;
  updated_at: string;
  created_by: any; // TODO:додати тип
  categories: CategoryProps[];
  employees: EmployeeListProps[];
  work_schedules: string;
  company_settings: ICompanySettingListProps[];
}

export type Status = string | number;

/**
 * @class Company
 * @classdesc
 * @property {string} id - backup id
 */
export default class Company extends Record({
  id: '0',
  uuid: '',
  title: '',
  phone: '',
  phone_checked: verification.STATUS_UNVERIFIED,
  email: '',
  email_checked: verification.STATUS_UNVERIFIED,
  description: '',
  cover_url: '',
  logo_url: '',
  qr_code_url: '',
  address: '',
  address_langitude: '',
  address_latitude: '',
  slogan: '',
  location_country: 'UKR',
  location_region: '',
  location_district: '',
  location_city: '',
  status: 0,
  created_at: '',
  updated_at: '',
  work_schedules: '',
  created_by: {} as any,
  categories: [],
  employees: [] as any,
  groups: [],
  company_settings: [],
}) {
  constructor(
    {groups, company_settings, ...props}: CompanyProps = {} as CompanyProps,
  ) {
    const options: any = {};

    if (Array.isArray(groups)) {
      options.groups = new GroupList({groups} as any);
    }

    if (Array.isArray(company_settings)) {
      const {settings} = new CompanySettingList({
        settings: company_settings,
      } as any);

      options.company_settings = settings;
    }

    super({
      ...props,
      employees: new EmployeeList({
        // @ts-ignore TODO: перевірити після додавання типу
        employees: props?.employees,
        total: props?.employees ? props?.employees?.length : 0,
      }),
      ...options,
    });
  }

  /**
   * @desc Статус НЕ верифікаваний
   * @param {String|Number} status
   * @return {Boolean}
   * */
  isStatusUnChecked(status: Status): boolean {
    return 1 * (status as any) === company.STATUS_UNCHECKED;
  }

  /**
   * @desc Статус верифікаваний
   * @param {String|Number} status
   * @return {Boolean}
   * */
  isStatusChecked(status: Status): boolean {
    return 1 * (status as any) === company.STATUS_ACTIVE;
  }

  /**
   * @desc Верифікація статусу в процесі
   * @param {String|Number} status
   * @return {Boolean}
   * */
  isStatusHandling(status: Status): boolean {
    return 1 * (status as any) === company.STATUS_HANDLING;
  }

  isOwner(userId: string): boolean {
    return this.created_by?.uuid === userId;
  }

  /**
   * @desc Перевіряє, якщо поле телефона, було верифіковано
   * @return {Boolean}
   * */
  isPhoneVerified(): boolean {
    return vHelper.isVerified(this.phone_checked);
  }

  /**
   * @desc Перевіряє, якщо поле email, було верифіковано
   * @return {Boolean}
   * */
  isEmailVerified(): boolean {
    return vHelper.isVerified(this.email_checked);
  }

  /**
   * @desc Перевіряє, якщо поле телефона, не було верифіковано (після зміни, наприклад)
   * @return {Boolean}
   * */
  isPhoneUnVerified(): boolean {
    return vHelper.isUnVerified(this.phone_checked);
  }

  /**
   * @desc Перевіряє, якщо поле email, не було верифіковано (після зміни, наприклад)
   * @return {Boolean}
   * */
  isEmailUnVerified(): boolean {
    return vHelper.isUnVerified(this.email_checked);
  }
}
