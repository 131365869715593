import React, {useCallback, useEffect, useState} from 'react';
import {Select as ANTSelect, SelectProps} from 'antd';
import {useDefaultForm} from '../Context';
import {isFunction, head} from '../../../services/helpers';
import {useTranslation} from 'react-i18next';

type OptionFunction<T> = (value: T) => string;

export interface ISelectProps<T>
  extends Omit<SelectProps<any>, 'children' | 'value'> {
  data: T[] | null;
  name: string;
  optionValue?: string | OptionFunction<T>;
  optionTitle?: string | OptionFunction<T>;
  selectFirst?: boolean;
}

const {Option} = ANTSelect;

export default function Select<T>({
  data,
  name,
  optionValue = 'uuid',
  optionTitle = 'title',
  selectFirst,
  onChange,
  ...props
}: ISelectProps<T>): JSX.Element {
  const {t} = useTranslation();

  const {valid, getFieldValue, setFieldsValue} = useDefaultForm();
  const [localDefaultValue, setLocalDefaultValue] = useState(null);

  const getFirstItem = useCallback(
    (data: any) => {
      const item: any = head(data);

      const value = isFunction(optionValue)
        ? optionValue(item)
        : (item as any)[optionValue];

      if (isFunction(onChange)) {
        onChange(value, {} as any);
      }

      setLocalDefaultValue(value);
      setFieldsValue({name: value});
    },
    [optionValue, onChange, setFieldsValue],
  );

  useEffect(() => {
    const value = getFieldValue(name);

    if ((data?.length === 1 && !value) || (selectFirst && !value && data)) {
      getFirstItem(data);
    }
  }, [data, getFieldValue, getFirstItem, name, selectFirst]);

  return (
    <>
      {name && valid ? (
        <ANTSelect
          {...props}
          onChange={onChange}
          value={getFieldValue(name) || localDefaultValue}
          loading={!data}>
          {data
            ? data.map((item, index) => {
                const value = isFunction(optionValue)
                  ? optionValue(item)
                  : (item as any)[optionValue];

                const title = isFunction(optionTitle)
                  ? optionTitle(item)
                  : (item as any)[optionTitle];

                return (
                  <Option key={`${value}${String(index)}`} value={value}>
                    {t(title)}
                  </Option>
                );
              })
            : null}
        </ANTSelect>
      ) : null}
    </>
  );
}
