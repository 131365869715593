import React, {useCallback} from 'react';
import {parse as parseQueryString} from 'query-string';
import {FlexContainer} from '../common/Styled';
import {HeaderWithoutLayout, FooterWithoutLayout} from '../common/Layout';
import styled from 'styled-components';
import {
  authLogoUrl,
  thankYouDescription,
  thankYouTitle,
} from '../../services/const';
import {Image} from 'antd';
import {Text} from '../common/Format';
import {withTranslation, WithTranslation} from 'react-i18next';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {Button} from '../common/General';
import {Routes} from '../../services/types';
import {getRootKey} from '../../services/helpers';
import {WithoutLayout} from '../common/Layout';

export default withTranslation()(withRouter(ThankYouPage));

export interface IThankYouPageProps
  extends WithTranslation,
    RouteComponentProps {}

const StyledPage = styled(FlexContainer)`
  width: 100vw;
  min-height: 100vh;
`;

const Content = styled(FlexContainer)`
  width: 100%;
  min-height: calc(100vh - 100px);
  padding: 2% 12%;
`;

const StyledLogo = styled(Image)`
  width: 250%;
  height: auto;
  margin-bottom: 5vh;

  @media (max-width: 668px) {
    width: 150%;
  }

  @media (max-width: 425px) {
    width: 100%;
  }
`;

const StyledTitle = styled(Text)`
  font-size: 3.5em;
  margin-bottom: 3vh;
`;

const StyledDescription = styled(Text)`
  font-size: 1em;
  margin-bottom: 5vh;
`;

function ThankYouPage({t, location, history}: IThankYouPageProps): JSX.Element {
  const {} = parseQueryString(location.search);
  const rootKey = getRootKey(location.pathname);

  const handleGoToSubscriptionPage = useCallback(() => {
    history.push(`/${Routes.app}/${Routes.subscription}`);
  }, [history]);

  return (
    <WithoutLayout>
      <StyledPage flexDirection="column">
        <HeaderWithoutLayout />
        <Content flexDirection="column" justifyContent="center">
          <StyledLogo src={authLogoUrl} alt="logo" preview={false} />
          <StyledTitle>{t(thankYouTitle)}</StyledTitle>
          <StyledDescription>{t(thankYouDescription)}</StyledDescription>
          <Button onClick={handleGoToSubscriptionPage}>
            {t('Go to subscription')}
          </Button>
        </Content>
        <FooterWithoutLayout />
      </StyledPage>
    </WithoutLayout>
  );
}
