import React from 'react';
import {Button, Tooltip} from 'antd';
import {ButtonProps} from 'antd/lib/button';
import styled from 'styled-components';

export interface IActionButtonProps extends ButtonProps {
  children: React.ReactNode;
  tooltip?: string;
}

const StyledButton = styled(Button)`
  border-radius: ${({theme}) => theme.border.baseBorderRadius};
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
`;

export default function ActionButton({
  children,
  type,
  size = 'small',
  loading,
  disabled,
  tooltip,
  ...rest
}: IActionButtonProps): JSX.Element {
  const {reportNS, tReady, defaultNS, i18nOptions, ...restProps}: any = rest;

  return (
    <Tooltip title={tooltip}>
      <StyledButton
        type={type || 'text'}
        size={size}
        loading={loading}
        disabled={disabled || loading}
        {...restProps}>
        {children}
      </StyledButton>
    </Tooltip>
  );
}
