import {Record} from 'immutable';
import {SubscriptionDTO} from '../dto';
import moment from 'moment';

export interface ISubscriptionModelReturnType {
  id: string;
  date_start: string;
  date_end: string;
  comment: string;
  created_at: string;
  price: string;
  periods: number;
  discount: number;
  status: number;
}

export class SubscriptionModel extends Record<ISubscriptionModelReturnType>({
  id: '',
  date_start: '',
  date_end: '',
  comment: '',
  created_at: '',
  price: '0.00',
  periods: 0,
  discount: 0,
  status: 0,
}) {
  constructor(props = {} as SubscriptionDTO) {
    const options: ISubscriptionModelReturnType = {
      id: props.id,
      date_start: props.date_start,
      date_end: props.date_end,
      comment: props.comment,
      created_at: props.created_at,
      price: props.price,
      periods: props.periods,
      discount: props.discount,
      status: props.status,
    };
    super(options);
  }

  toDateFormat(date: string) {
    return moment(date).format('YYYY-MM-DD');
  }

  toFormatStartDate() {
    return this.toDateFormat(this.date_start);
  }

  toFormatEndDate() {
    return this.toDateFormat(this.date_end);
  }
}
