import React from 'react';
import PhoneInput, {PhoneInputProps} from 'react-phone-input-2';
import classNames from 'classnames';

import 'react-phone-input-2/lib/style.css';
import './PhoneInput.less';

export interface IPhoneInputComponentProps extends PhoneInputProps {
  loading?: boolean;
  id?: string;
  dataTestid?: string;
  className?: string;
}

/**
 * @desc Компонент для виводу
 * */
export default function PhoneInputComponent({
  loading = false,
  id = 'field-phone',
  dataTestid = '',
  placeholder = '',
  searchClass = 'field-phone-search',
  inputClass = 'field-phone ant-input',
  containerClass = 'field-phone-container',
  className,
  value,
  ...rest
}: IPhoneInputComponentProps): JSX.Element {
  return (
    <PhoneInput
      autoFormat
      enableSearch
      disableSearchIcon
      searchClass={searchClass}
      searchPlaceholder={placeholder}
      inputProps={{
        id,
        'data-testid': dataTestid,
        disabled: loading,
      }}
      disabled={loading}
      disableCountryCode={loading}
      disableDropdown={loading}
      inputClass={`${inputClass} field-phone`}
      containerClass={classNames(`${containerClass} ${className}`, {
        'field-phone--disabled': loading,
      })}
      buttonClass={classNames(``, {
        'field-phone--disabled': loading,
      })}
      value={value}
      country="ua"
      placeholder={placeholder}
      alwaysDefaultMask
      {...rest}
    />
  );
}
