import {Record, List} from 'immutable';
import {PlanDTO} from '../dto';
import {SubscriptionModel} from './SubscriptionModel';
import {SubscriptionListMapper} from '../mapper';
import {len, sortedByDate} from '../../services/helpers';

export interface IPlanModelReturnType {
  id: string;
  identifier: string;
  checkout_url: string;
  subscriptions: List<SubscriptionModel>;
  status: number;
  created_at: string;
}

export class PlanModel extends Record<IPlanModelReturnType>({
  id: '',
  identifier: '',
  checkout_url: '',
  subscriptions: List(),
  status: 0,
  created_at: '',
}) {
  constructor(props = {} as PlanDTO) {
    const options: IPlanModelReturnType = {
      id: props.id,
      identifier: props.identifier,
      subscriptions: SubscriptionListMapper.toSubscriptionListModel(
        props?.subscriptions || [],
        len(props?.subscriptions) || 0,
      ).subscriptions,
      status: props.status,
      checkout_url: props.checkout_url,
      created_at: props.created_at,
    };
    super(options);
  }

  getFirstSubscription(): SubscriptionModel[] {
    return [this.subscriptions.sort(sortedByDate('date_end')).last()];
  }

  getRestSubscription(): List<SubscriptionModel> {
    return this.subscriptions.sort(sortedByDate('date_end')).pop();
  }

  isEmptySubscriptionList(): boolean {
    return !this.getRestSubscription().size;
  }

  isEmptyFirstSubscription(): boolean {
    return !this.getFirstSubscription().length;
  }
}
