import React, {useRef} from 'react';
import {ILiteralObj} from '../../../services/types';
import {Row, Col, Divider} from 'antd';
import {withTranslation, WithTranslation} from 'react-i18next';
import Label from './Label';
import styled from 'styled-components';
import {useSize} from '../hooks';
import {bigPhoneScreen} from '../../../services/const';

export default withTranslation()(CardContent);

export interface ICardContentProps extends WithTranslation {
  entity: ILiteralObj;
}

const StyledRow = styled(Row)`
  width: 100%;
  margin: 0 !important;
  padding: 10px;
`;

const StyledDivider = styled(Divider)`
  margin: 10px 0;
`;

const StyledValue = styled('span')`
  font-weight: bold;
`;

function CardContent({entity, t}: ICardContentProps): JSX.Element {
  const contentRef = useRef(null);
  const {width: contentWidth} = useSize(contentRef);

  return (
    <StyledRow gutter={20} ref={contentRef}>
      {Object.entries(entity).map(([key, value]: string[]) => (
        <Col
          span={contentWidth < bigPhoneScreen ? 24 : 12}
          key={`${key}-${value}`}>
          <StyledDivider />
          <Label label={`${t(key)}:`}>
            <StyledValue>{value}</StyledValue>
          </Label>
        </Col>
      ))}
    </StyledRow>
  );
}
