import {PlanDTO, IPlanDTOProps} from './PlanDTO';
export interface IPlanListDTOProps {
  plans: IPlanDTOProps[];
}

export class PlanListDTO {
  public plans: IPlanDTOProps[];

  constructor({plans = []}: IPlanListDTOProps) {
    this.plans = plans.map((plan) => new PlanDTO(plan));
  }
}
