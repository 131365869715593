import {ISubscriptionDTOProps} from './SubscriptionDTO';
import {SubscriptionListMapper} from '../mapper';

export interface IPlanDTOProps {
  id: string;
  identifier: string;
  checkout_url: string;
  subscriptions: ISubscriptionDTOProps[];
  status: number;
  created_at: string;
}

export class PlanDTO {
  public id: string;
  public identifier: string;
  public checkout_url: string;
  public status: number;
  public created_at: string;
  public subscriptions: ISubscriptionDTOProps[];

  constructor(props?: IPlanDTOProps) {
    this.id = props?.id || '';
    this.identifier = props?.identifier || '';
    this.checkout_url = props?.checkout_url || '';
    this.status = props?.status ?? 0;
    this.created_at = props?.created_at || '';
    this.subscriptions = SubscriptionListMapper.toSubscriptionListDTO(
      props?.subscriptions || [],
    );
  }
}
