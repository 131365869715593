import * as React from 'react';

export interface IUseIntervalReturnProps {
  intervalCallback: () => void | Promise<void>;
  handleClearInterval: () => void;
}

export default function useInterval(
  callback: any,
  delay: number | null,
  limit?: number,
): IUseIntervalReturnProps {
  const savedCallback = React.useRef(callback);
  const id = React.useRef<any | null>(null);

  const handleClearInterval = React.useCallback((interval: any): void => {
    if (interval?.current) {
      clearInterval(interval?.current);
    }
  }, []);

  const intervalCallback = React.useCallback(() => {
    if (delay) {
      id.current = setInterval(() => savedCallback.current(), delay);
    }
  }, [delay]);

  React.useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const interval = id;

    if (limit) {
      setTimeout(() => {
        handleClearInterval(id);
      }, limit);
    }

    return () => {
      handleClearInterval(interval);
    };
  }, [delay, handleClearInterval, limit]);

  return {
    intervalCallback,
    handleClearInterval: () => handleClearInterval(id),
  };
}
