import React, {useCallback, useState} from 'react';
import {Checkbox} from 'antd';
import {withTranslation, WithTranslation} from 'react-i18next';
import {CheckboxProps, CheckboxChangeEvent} from 'antd/lib/checkbox';
import {isFunction} from '../../../services/helpers';
import {useDefaultForm} from '../Context';
import styled from 'styled-components';

export default withTranslation()(FormCheckBox);

export type ReturnedProps = {
  checked: boolean;
};

export interface IProps
  extends WithTranslation,
    Omit<CheckboxProps, 'onChange' | 'children'> {
  isChecked?: boolean;
  title?: string;
  name: string;
  children?: (data: ReturnedProps) => React.ReactNode;
  onChange?: (value: boolean) => void;
}

const StyledCheckbox = styled(Checkbox)`
  & .ant-checkbox-inner {
    width: 14px;
    height: 14px;
    margin-bottom: 1px;

    &::after {
      transform: rotate(45deg) scale(1) translate(-80%, -49%) !important;
    }
  }

  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({theme}) => theme.colors.link};
    border-color: ${({theme}) => theme.colors.link};
  }
`;

function FormCheckBox({
  t,
  isChecked = false,
  title = '',
  name = 'checkbox',
  children,
  onChange,
  ...rest
}: IProps): JSX.Element {
  const {setFieldsValue} = useDefaultForm();

  const [checked, setChecked] = useState<boolean>(isChecked);

  const onCheckBoxChange = useCallback(
    (event: CheckboxChangeEvent): void => {
      setChecked((prevState) => {
        if (isFunction(setFieldsValue)) {
          setFieldsValue({[name]: !prevState});
        }

        if (isFunction(onChange)) {
          onChange(event?.target?.checked);
        }

        return !prevState;
      });
    },
    [name, onChange, setFieldsValue],
  );

  return (
    <StyledCheckbox checked={checked} onChange={onCheckBoxChange} {...rest}>
      {isFunction(children) ? children({checked}) : title || null}
    </StyledCheckbox>
  );
}
