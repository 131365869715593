import {useEffect, useState} from 'react';

/**
 * Listens to the router and updates the selected activeKey
 * @param initialKey
 * @param history
 * @returns {any[]}
 */

export type UseActiveKeyReturnType = [string];

export default function useActiveKey(
  initialKey: string,
  history: any,
): UseActiveKeyReturnType {
  const [activeKey, setActiveKey] = useState<string>(null as any);

  useEffect(() => {
    setActiveKey(initialKey);
    const stopListeningHistory = history.listen((location: any) => {
      setActiveKey(location.pathname);
    });

    return () => stopListeningHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [activeKey];
}
