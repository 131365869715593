import {
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_PROCESSING,
  SUBSCRIPTION_STATUS_DISABLED,
  SUBSCRIPTION_STATUS_EXPIRED,
} from '../helpers';

export interface ILiteralObj {
  [key: string]: any;
}

export type WithOptional<T, K extends keyof T> = Omit<T, K> &
  Partial<Pick<T, K>>;

export enum REDUX_STATUS {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export type KeyOfObject<T> = {
  [P in keyof T]: T[P];
};

export enum Routes {
  auth = 'auth',
  app = 'app',
  login = 'login',
  forgotPassword = 'forgot-password',
  profile = 'profile',
  subscription = 'subscription',
  dashboard = 'dashboard',
  thankYou = 'thank-you-page',
  privacyAndGDPRS = 'privacy-and-gdprs',
  termsAndConditions = 'terms-and-conditions',
}

export enum Menu_Title {
  profile = 'Profile',
  subscription = 'Subscription',
  dashboard = 'Personal office',
}

export enum GENDER {
  MALE = 'male',
  FEMALE = 'female',
}

export type ApiError = {
  message: string;
  status: number;
};

export type ApiAnswer = {
  code: number;
  count?: number;
  success: boolean;
};

export interface IListProps {
  limit: number;
  offset: number;
}

export interface IListSearchProps extends IListProps {
  keywords?: string;
}

export interface IEntityProps {
  loadOnInit?: boolean;
  showLoading?: boolean;
}

export const PLAN_STATUS: {[key: string]: any} = {
  NotActivated: SUBSCRIPTION_STATUS_DISABLED,
  In_processing: SUBSCRIPTION_STATUS_PROCESSING,
  Active: SUBSCRIPTION_STATUS_ACTIVE,
  Expired: SUBSCRIPTION_STATUS_EXPIRED,
} as const;
export const PLAN_STATUS_TEXT: {[key: string]: any} = {
  [SUBSCRIPTION_STATUS_DISABLED]: 'NotActivated',
  [SUBSCRIPTION_STATUS_PROCESSING]: 'In_processing',
  [SUBSCRIPTION_STATUS_ACTIVE]: 'Subscription active',
  [SUBSCRIPTION_STATUS_EXPIRED]: 'Expired',
} as const;

export type KEYS_PLAN_STATUS = keyof typeof PLAN_STATUS;
export type VALUES_PLAN_STATUS = (typeof PLAN_STATUS)[KEYS_PLAN_STATUS];

export enum app_languages {
  Ukrainian = 'uk',
  English = 'en-us',
  Russian = 'ru-ru',
}
