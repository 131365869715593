import React, {useState} from 'react';
import {Form} from 'antd';
import {DefaultFormManager} from '../Context';
import DefaultForm, {IDefaultLocalFormProps} from './DefaultForm';

export interface IDefaultFormProps<T, U>
  extends Omit<
    IDefaultLocalFormProps<T, U>,
    'form' | 'loadingSubmit' | 'setLoadingSubmit'
  > {}

export default function DefaultStateForm<T, U>({
  children,
  initialValues,
  ...rest
}: IDefaultFormProps<T, U>): JSX.Element {
  const [form] = Form.useForm();
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  return (
    <DefaultFormManager<T>
      form={{...form}}
      initialValues={initialValues}
      loadingSubmit={loadingSubmit}>
      <DefaultForm<T, U>
        form={form}
        setLoadingSubmit={setLoadingSubmit}
        initialValues={initialValues}
        {...rest}>
        {children}
      </DefaultForm>
    </DefaultFormManager>
  );
}
