import React, {useCallback} from 'react';
import {Spin} from 'antd';
import {AppErrorBoundary} from '../Errors';
import MainLayout from './MainLayout';
import {withTranslation, WithTranslation} from 'react-i18next';
import {logout} from '../../../services/profile';
import {APP_TITLE} from '../../../services/const';
import {useStoredProfile} from '../hooks';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {TokenHandler} from '../../../services/core';
import {Routes} from '../../../services/types';

export default withTranslation()(withRouter(MainRouteLayout));

export interface IMainRouteLayoutProps
  extends WithTranslation,
    RouteComponentProps {
  children: any;
  path: string;
}

function MainRouteLayout({children, t, history}: IMainRouteLayoutProps) {
  const {user, adminRoutes} = useStoredProfile();

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      await logout();
      TokenHandler.destroyToken();
      history.push(`/${Routes.auth}/${Routes.login}`);
    } catch (e: any) {
      console.log(e);
    }
  }, [history]);

  return (
    <Spin size="large" tip={t('Please wait...')} spinning={false} delay={500}>
      <AppErrorBoundary>
        <MainLayout
          handleLogout={handleLogout}
          appTitle={APP_TITLE}
          profile={user}
          adminRoutes={adminRoutes}>
          {children}
        </MainLayout>
      </AppErrorBoundary>
    </Spin>
  );
}
