import axios, {Method} from 'axios';
import {authUrl, LocalStorageItems, AcceptLanguageItem} from '../const';
import {TokenHandler} from './token';
import {ILiteralObj, Routes} from '../types';
import {getCookie, isFunction, curry} from '../helpers';

const {location} = window;

const isDestroyToken = () => {
  const token = TokenHandler.getAccessToken() || getCookie('access_token');
  if (token) {
    TokenHandler.destroyToken();
  }
};

export enum ErrorsStatus {
  Bad_Request = 400,
  Unauthorized = 401,
  Forbidden = 403,
  Conflict = 409,
  Expired_Invalid_Token = 498,
  Server_Error = 500,
  Unknown = 600,
}

interface ErrorStatusActions {
  status: number;
  error: {message: string; status: number};
  ignoreRedirect: boolean | ErrorsStatus[];
  reject: any;
  withCheckAccessToken?: boolean;
  rejectedRequest?: (...arg: any[]) => Promise<any>;
  headers?: ILiteralObj;
  serverErrorRedirect?: boolean;
}

interface IRefreshTokenProps {
  headers?: ILiteralObj;
  errorCallback: (value: Partial<ErrorStatusActions>) => Promise<any>;
  rejectedRequest?: (...arg: any[]) => Promise<any>;
}

export const onRefreshToken = async ({
  headers = {},
  errorCallback,
  rejectedRequest,
}: IRefreshTokenProps): Promise<void> => {
  try {
    const refresh_token =
      TokenHandler.getRefreshToken() || getCookie('refresh_token');

    if (!refresh_token) {
      return location.assign(
        `${location.origin}/${Routes.auth}/${Routes.login}`,
      );
    }

    const {data}: any = await axios.post(`${authUrl}/token/refresh`, {
      token: refresh_token,
    });

    TokenHandler.setAccessToken(data.access_token);

    if (isFunction(rejectedRequest)) {
      const updatedHeaders = {
        ...headers,
        ...{
          Authorization: `Bearer ${data.access_token}`,
        },
      };

      await rejectedRequest({headers: updatedHeaders});
    }
  } catch (error: any) {
    const errorMessage = {
      message: error.response?.data.error || error.message,
      status: error.response?.status || error?.status,
    };

    await errorCallback({
      status: error.response?.status,
      error: errorMessage,
      serverErrorRedirect: true,
    });
  }
};

const isIgnoreRedirect = curry<[boolean | number[], number], boolean>(
  (redirect: boolean | number[], status: number): boolean =>
    Array.isArray(redirect) ? !redirect.includes(status) : !redirect,
);

export const errorStatusActions = async ({
  status,
  error,
  ignoreRedirect,
  reject,
  withCheckAccessToken,
  rejectedRequest,
  headers,
  serverErrorRedirect = false,
}: ErrorStatusActions): Promise<any> => {
  const isRedirect = isIgnoreRedirect(ignoreRedirect);

  switch (status) {
    case ErrorsStatus.Unauthorized:
      if (withCheckAccessToken) {
        return await onRefreshToken({
          headers,
          rejectedRequest,
          errorCallback: async (value: any) =>
            await errorStatusActions({
              reject,
              ignoreRedirect,
              withCheckAccessToken: false,
              ...value,
            }),
        });
      }

      if (isRedirect(ErrorsStatus.Unauthorized)) {
        isDestroyToken();
        location.assign(`${location.origin}/${Routes.auth}/${Routes.login}`);
      }
      // throw new UnauthorizedApiError(error);
      return reject(error);

    // forbidden
    case ErrorsStatus.Forbidden:
      if (isRedirect(ErrorsStatus.Forbidden)) {
        isDestroyToken();
        location.assign(`${location.origin}/${Routes.auth}/${Routes.login}`);
      }

      // throw new ForbiddenApiError(error);
      return reject(error);

    // conflict
    case ErrorsStatus.Conflict:
      // throw new ConfictApiError(error);
      return reject(error);

    case ErrorsStatus.Expired_Invalid_Token:
      if (isRedirect(ErrorsStatus.Expired_Invalid_Token)) {
        isDestroyToken();
        location.assign(`${location.origin}/${Routes.auth}/${Routes.login}`);
      }
      // throw new UnauthorizedApiError(error);
      return reject(error);

    // catch all
    default:
      if (
        (status >= ErrorsStatus.Server_Error &&
          status < ErrorsStatus.Unknown) ||
        !status
      ) {
        if (serverErrorRedirect) {
          isDestroyToken();
          location.assign(`${location.origin}/${Routes.auth}/${Routes.login}`);
        }
        return reject(error);
      }
      break;
  }
};

export async function request<T, U>(
  uri: string,
  data: T | null = null,
  method: Method = 'POST',
  ignoreRedirect: boolean | ErrorsStatus[] = false,
  inHeaders: ILiteralObj = {},
  isMultipartData: boolean = false,
): Promise<U> {
  return new Promise(async (resolve, reject) => {
    let body: any;
    if (data) {
      body = isMultipartData ? data : JSON.stringify(data);
    }

    const appInfo = localStorage.getItem(LocalStorageItems.xAppInfo);
    const deviceInfo = localStorage.getItem(LocalStorageItems.xDeviceInfo);
    const language = localStorage.getItem(LocalStorageItems.language) || 'ua';

    let headers = {
      ...{
        'Content-Type': 'application/json',
        'Content-Language': 'ua,ru,en',
        'Accept-Language': AcceptLanguageItem[language],
        [LocalStorageItems.xAppInfo]: appInfo,
        [LocalStorageItems.xDeviceInfo]: deviceInfo,
      },
      ...inHeaders,
    };

    const token = TokenHandler.getAccessToken() || getCookie('access_token');

    if (token) {
      headers = {
        ...{
          Authorization: `Bearer ${token}`,
        },
        ...headers,
      };
    }

    if (isMultipartData) {
      // @ts-ignore
      delete headers['Content-Type'];
    }

    const apiRequest = async (value: any = {}) => {
      const res = await axios({
        method,
        url: uri,
        headers,
        data: body,
        withCredentials: true,
        ...value,
      });
      return resolve(res?.data);
    };

    try {
      await apiRequest();
    } catch (error: any) {
      const errorMessage = {
        message: error.response?.data?.error || error?.message,
        status: error.response?.status || error?.status,
      };

      const updateHeaders = (({Authorization, ...rest}: any) => ({...rest}))(
        headers,
      );

      await errorStatusActions({
        status: errorMessage?.status,
        error: errorMessage,
        ignoreRedirect,
        reject,
        withCheckAccessToken: true,
        rejectedRequest: apiRequest,
        headers: updateHeaders,
      });

      return reject(errorMessage);
    }
  });
}
