import * as React from 'react';
import {getProfile} from '../../../services/profile';
import {UserModel, UserMapper, UserDTO} from '../../../struture';
import useCancellablePromise from './useCancellablePromise';
import useStateReducer from './useStateReducer';

export interface IUseUserProps {
  loadingOnInit?: boolean;
}

export interface IUseUserReturnType {
  error: null | any;
  loading: boolean;
  entity: UserModel | null;
  refresh: () => Promise<UserModel | void>;
}

export default function useUser({
  loadingOnInit = true,
}: IUseUserProps = {}): IUseUserReturnType {
  const {cancellablePromise, didCancel} = useCancellablePromise();
  const {entity, loading, error, handleUpdate} = useStateReducer<
    Omit<IUseUserReturnType, 'refresh'>
  >({
    entity: null,
  });

  const refresh = React.useCallback(
    async (showLoading: boolean = true): Promise<UserModel | void> => {
      try {
        handleUpdate({loading: showLoading, error: null});

        const user = await cancellablePromise<UserDTO>(getProfile());

        const userModel = UserMapper.toUserModel(user);

        if (!didCancel.current) {
          handleUpdate({
            entity: userModel,
            loading: false,
          });
          return userModel;
        }
      } catch (err: any) {
        if (!didCancel.current) {
          handleUpdate({
            error: err,
            loading: false,
          });
        }
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  React.useEffect(() => {
    if (loadingOnInit) {
      (async () => {
        await refresh();
      })();
    }
  }, [loadingOnInit, refresh]);

  return {
    error,
    loading,
    refresh,
    entity,
  };
}
