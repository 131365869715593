import React from 'react';
import {Text} from '../../common/Format';
import styled from 'styled-components';

export interface IProfileFormHeaderProps {
  title: string;
}

const Container = styled.div`
  width: 100%;
  padding: 10px 10px;
  font-weight: 500;
  background-color: ${({theme}) => theme.colors.disable};
  border-top-left-radius: ${({theme}) => theme.border.baseBorderRadius};
  border-top-right-radius: ${({theme}) => theme.border.baseBorderRadius};
  margin-bottom: 20px;
`;

const StyledText = styled(Text)`
  font-size: 16px;
`;

export default function ProfileFormHeader({
  title = '',
}: IProfileFormHeaderProps): JSX.Element {
  return (
    <Container>
      <StyledText>{title}</StyledText>
    </Container>
  );
}
