import React, {useState, useCallback, Suspense, useEffect} from 'react';
import {Layout, Spin} from 'antd';
import {withTranslation, WithTranslation} from 'react-i18next';
import {useLocalStorage} from '../hooks';
import Header from './Header';
import Footer from './Footer';
import Content from './Content';
import MainMenu from './MainMenu';
import SideBar from './SideBar';
import styled, {css} from 'styled-components';
import {fromBooleanToNumber} from '../../../services/helpers';
import {FullHeightLayout} from '../Styled';
import {UserModel} from '../../../struture';
import {Routes} from '../../../services/types';

const MainMenuSideWindowLazy = React.lazy(() => import('./MainMenuSideWindow'));

const mediaQueryList = matchMedia('(max-width: 767px)');

export default withTranslation()(MainLayout);

export interface IMainLayoutProps extends WithTranslation {
  children: React.ReactNode;
  handleLogout: () => Promise<void>;
  appTitle: string;
  profile: UserModel | null;
  adminRoutes: Routes[];
}

const StyledInnerLayout = styled(Layout)<{collapse: number}>`
  transition: margin-left 0.3s;
  margin-left: 225px;
  background-color: ${({theme}) => theme.colors.app};

  ${({collapse}) =>
    collapse &&
    css`
      margin-left: 0;
    `}
`;

function MainLayout({
  children,
  handleLogout,
  appTitle,
  profile,
  adminRoutes,
}: IMainLayoutProps) {
  const [siderCollapsed, setSiderCollapsed] = useLocalStorage(
    'sider-collapsed',
    false,
  );

  const [menuSideWindowVisible, setMenuSideWindowVisible] = useState(false);

  const handleOpenMenuSideWindow = useCallback((): void => {
    setMenuSideWindowVisible(true);
  }, []);

  const [isSmallScreen, setIsSmallScreen] = useState(mediaQueryList?.matches);

  const onSiderCollapsed = useCallback(() => {
    if (isSmallScreen) {
      handleOpenMenuSideWindow();
    } else {
      setSiderCollapsed((prev: boolean) => !prev);
    }
  }, [handleOpenMenuSideWindow, isSmallScreen, setSiderCollapsed]);

  useEffect(() => {
    const resetSideWindow = ({matches}: any): void => {
      setMenuSideWindowVisible(false);
      setIsSmallScreen(matches);
    };

    mediaQueryList.addEventListener('change', resetSideWindow);

    return () => mediaQueryList.removeEventListener('change', resetSideWindow);
  }, []);

  return (
    <>
      <FullHeightLayout data-testid="main-layout">
        <Layout>
          <Header
            profile={profile}
            handleLogout={handleLogout}
            appTitle={appTitle}
            handleCollapsedMenu={onSiderCollapsed}
            collapsed={siderCollapsed}
          />
          <Layout>
            <SideBar collapsed={siderCollapsed}>
              <MainMenu appTitle={appTitle} adminRoutes={adminRoutes} />
            </SideBar>
            <StyledInnerLayout
              collapse={
                fromBooleanToNumber(siderCollapsed) ||
                fromBooleanToNumber(isSmallScreen)
              }>
              <>
                <Content>{children || <span>no content</span>}</Content>
                <Footer />
              </>
            </StyledInnerLayout>
          </Layout>
        </Layout>
      </FullHeightLayout>
      <Suspense fallback={<Spin />}>
        <MainMenuSideWindowLazy
          visible={menuSideWindowVisible}
          setVisible={setMenuSideWindowVisible}
          appTitle={appTitle}
          adminRoutes={adminRoutes}
        />
      </Suspense>
    </>
  );
}
