import {DefaultTheme} from 'styled-components';

const theme: DefaultTheme = {
  size: {
    fontSizeBase: '14px',
  },

  border: {
    baseBorderRadius: '5px',
  },

  colors: {
    primary: '#404041',
    secondary: '#363a41',
    third: '#3c434a',
    link: '#1473e6',
    info: '#1890ff',
    white: '#fff',
    whiteHover: 'rgba(255, 255, 255, 0.85)',
    success: 'rgb(122, 183, 43)',
    successHover: 'rgba(122, 183, 43, 0.7)',
    warning: '#faad14',
    error: '#EF4136',
    disable: 'rgba(64, 64, 65, 0.25)',
    lightDisable: 'rgba(64, 64, 65, 0.1)',
    heading: 'rgba(64, 64, 64, 0.85)',
    app: '#fff',
    appHover: '#E6E6E6',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    green: '#4eb15b',
    greenHover: '#5ACD69',
    borderColor: '#262626',
    mainBackground: '#3e58cf',
    black: '#000',
    background: '#eef2f6',
    thirdTextColor: '#fff',
    orange: '#EF8E02',
    buttonPrimaryColor: '#E24411',
    buttonSecondaryColor: '#EF8E02',
    buttonShadowColor: 'rgba(254, 111, 40, 0.5)',
    buttonActivePrimaryColor: '#D63C0A',
    buttonActiveSecondaryColor: '#DE8401',
    buttonActiveShadowColor: '#FE6F28',
  },

  activeButton: {
    buttonPrimaryColor: '#86b868',
    buttonSecondaryColor: '#a5d279',
    buttonShadowColor: '#e2fed1',
    buttonActivePrimaryColor: '#86b868',
    buttonActiveSecondaryColor: '#a5d279',
    buttonActiveShadowColor: '#e2fed1',
  },

  acceptModal: {
    title: '#F15A22',
    content: '#000',
    subText: '#282828',
  },

  icon: {
    green: '#4eb15b',
    slateBlue: '#6e55df',
    darkOrchid: '#9d37b5',
    mint: '#51bf97',
    lightCherry: '#d94658',
    blueGrayCrayola: '#5e95c5',
    greyBlackberry: '#6a6c73',
    yellowGold: '#cd9c37',
  },
};

export {theme};
