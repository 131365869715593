import React from 'react';
import Spin, {SpinProps} from '../General/Spin';
import styled, {css} from 'styled-components';

export interface IScreenLoaderProps extends Partial<SpinProps> {
  full?: boolean;
}

const FullScreen = styled.div<{full?: boolean}>`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({full}) =>
    full &&
    css`
      min-height: 100vh;
      background-color: ${({theme}) => theme.colors.app};
    `}
`;

export default function ScreenLoader({full, ...rest}: IScreenLoaderProps) {
  return (
    <FullScreen full={full}>
      <Spin {...rest} />
    </FullScreen>
  );
}
