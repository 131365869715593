import React, {useEffect} from 'react';
import {Route, RouteProps, useHistory, useRouteMatch} from 'react-router-dom';
import {
  MainRouteLayout,
  ScreenLoader,
  WithoutLayout,
} from '../../components/common/Layout';
import {useStoredProfile} from '../../components/common/hooks';
import {REDUX_STATUS, Routes as RoutesType} from '../../services/types';
import {TokenHandler} from '../../services/core';
import {List} from 'immutable';
import {isActivePlan} from '../../services/helpers';

export interface IPrivateReturnType {
  path: string;
}

export interface IPrivateRouteProps {
  children: (value: IPrivateReturnType) => any;
}

PrivateRoute.MainLayout = ({
  children,
  path,
  ...rest
}: RouteProps): JSX.Element => (
  <MainRouteLayout path={path as string}>
    <Route path={path} {...rest}>
      {children || null}
    </Route>
  </MainRouteLayout>
);
PrivateRoute.WithoutLayout = ({children, ...rest}: RouteProps): JSX.Element => (
  <WithoutLayout>
    <Route {...rest}>{children}</Route>
  </WithoutLayout>
);

export default function PrivateRoute({
  children,
}: IPrivateRouteProps): JSX.Element {
  const {path} = useRouteMatch();
  const {status, user} = useStoredProfile();
  const history = useHistory();

  const access_token = TokenHandler.getAccessToken();
  const is_verify_email = TokenHandler.getVerifyEmailStatus();

  useEffect(() => {
    if (!access_token || is_verify_email !== '1') {
      TokenHandler.destroyToken();
      history.push(`/${RoutesType.auth}/${RoutesType.login}`);
    }
  }, [is_verify_email, history, access_token]);

  useEffect(() => {
    const isRedirectToSubscription = localStorage.getItem(
      'is_redirect_to_subscription',
    );

    if (List.isList(user?.plans)) {
      const isActive = isActivePlan(user?.plans);

      if (
        isRedirectToSubscription === null &&
        isRedirectToSubscription !== 'false' &&
        !isActive
      ) {
        history.push(`${path}/${RoutesType.subscription}`);
        localStorage.setItem('is_redirect_to_subscription', 'false');
      }
    }
  }, [history, path, user?.plans]);

  return status === REDUX_STATUS.SUCCEEDED ||
    status === REDUX_STATUS.LOADING ||
    status === REDUX_STATUS.FAILED ? (
    <>{children({path})}</>
  ) : (
    <ScreenLoader full size={50} />
  );
}
