import {PlanModel} from '../models';
import {correctPrice} from '../../services/helpers';

export class PlanFormDTO {
  public uuid: string;
  public identifier: string;
  public comment: string;
  public price: string;
  public periods: number;
  public discount: number;
  public amount: string;

  constructor(props?: PlanModel) {
    this.uuid = props?.id || '';
    this.identifier = props?.identifier || '';
    this.comment = props?.subscriptions?.first()?.comment || '';
    this.price = props?.subscriptions?.first()?.price
      ? correctPrice(props?.subscriptions?.first()?.price)
      : '0.00';
    this.periods = props?.subscriptions?.first()?.periods ?? 0;
    this.discount = props?.subscriptions?.first()?.discount ?? 0;
    this.amount = props?.subscriptions?.first()?.price
      ? correctPrice(props?.subscriptions?.first()?.price)
      : '0.00';
  }
}
