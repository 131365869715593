import styled from 'styled-components';
import {Button} from '../../common/General';

export const StyledSubscriptionButton = styled(Button)`
  width: 100%;
  margin-top: 15px;
  background: -webkit-linear-gradient(
    295deg,
    ${({theme}) => theme.activeButton.buttonPrimaryColor} 0%,
    ${({theme}) => theme.activeButton.buttonSecondaryColor} 100%
  );
  background: -o-linear-gradient(
    295deg,
    ${({theme}) => theme.activeButton.buttonPrimaryColor} 0%,
    ${({theme}) => theme.activeButton.buttonSecondaryColor} 100%
  );
  background: linear-gradient(
    25deg,
    ${({theme}) => theme.activeButton.buttonPrimaryColor} 0%,
    ${({theme}) => theme.activeButton.buttonSecondaryColor} 100%
  );
  box-shadow: 0 0 20px ${({theme}) => theme.activeButton.buttonShadowColor};

  &:hover {
    background: -webkit-linear-gradient(
      295deg,
      ${({theme}) => theme.activeButton.buttonPrimaryColor} 0%,
      ${({theme}) => theme.activeButton.buttonSecondaryColor} 100%
    );
    background: -o-linear-gradient(
      295deg,
      ${({theme}) => theme.activeButton.buttonPrimaryColor} 0%,
      ${({theme}) => theme.activeButton.buttonSecondaryColor} 100%
    );
    background: linear-gradient(
      25deg,
      ${({theme}) => theme.activeButton.buttonPrimaryColor} 0%,
      ${({theme}) => theme.activeButton.buttonSecondaryColor} 100%
    );

    box-shadow: 0 0 30px
      ${({theme}) => theme.activeButton.buttonActiveSecondaryColor};
  }

  &:active,
  &:focus {
    background: -webkit-linear-gradient(
      295deg,
      ${({theme}) => theme.activeButton.buttonActivePrimaryColor} 0%,
      ${({theme}) => theme.activeButton.buttonActiveSecondaryColor} 100%
    );
    background: -o-linear-gradient(
      295deg,
      ${({theme}) => theme.activeButton.buttonActivePrimaryColor} 0%,
      ${({theme}) => theme.activeButton.buttonActiveSecondaryColor} 100%
    );
    background: linear-gradient(
      25deg,
      ${({theme}) => theme.activeButton.buttonActivePrimaryColor} 0%,
      ${({theme}) => theme.activeButton.buttonActiveSecondaryColor} 100%
    );
    box-shadow: 0 0 10px
      ${({theme}) => theme.activeButton.buttonActiveSecondaryColor};
  }
`;
