import React from 'react';
import {RouteProps, Route, useHistory, useRouteMatch} from 'react-router-dom';
import {AuthRouteLayout, ScreenLoader} from '../../components/common/Layout';
import {usePreLoadCookie} from '../../components/common/hooks';
import {Routes as RoutesType} from '../../services/types';

export interface IAuthRouteReturnType {
  path: string;
}

export interface IAuthRouteProps {
  children: (value: IAuthRouteReturnType) => React.ReactNode;
}

AuthRoute.MainLayout = ({children, ...rest}: RouteProps): JSX.Element => (
  <AuthRouteLayout>
    <Route {...rest}>{children || null}</Route>
  </AuthRouteLayout>
);

export default function AuthRoute({children}: IAuthRouteProps): JSX.Element {
  const {access_token, is_verify_email} = usePreLoadCookie();

  const history = useHistory();
  const {path} = useRouteMatch();

  React.useEffect(() => {
    if (access_token && is_verify_email === '1') {
      history.push(`/${RoutesType.app}`);
    }
  }, [access_token, is_verify_email, history]);

  return access_token && is_verify_email === '1' ? (
    <ScreenLoader full size={50} />
  ) : (
    <>{children({path})}</>
  );
}
