import React from 'react';
import {Text} from '../Format';
import styled from 'styled-components';
import {Col, Row} from 'antd';

export interface IWarningProps {
  children?: React.ReactNode | React.ReactNode[];
  title?: string;
}

const StyledRow = styled(Row)`
  background-color: ${({theme}) => theme.colors.warning};
  margin-bottom: 20px;
  padding: 20px;
  border-radius: ${({theme}) => theme.border.baseBorderRadius};

  & :first-child:not(:only-child) {
    margin-bottom: 20px;
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled(Text)`
  color: ${({theme}) => theme.colors.white};
`;

export function Warning({title, children}: IWarningProps): JSX.Element {
  return (
    <StyledRow gutter={20}>
      {title ? (
        <StyledCol span={24}>
          <StyledTitle>{title}</StyledTitle>
        </StyledCol>
      ) : null}
      {children ? <StyledCol span={24}>{children}</StyledCol> : null}
    </StyledRow>
  );
}
