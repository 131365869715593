import React from 'react';
import {Col, Row, Button} from 'antd';
import {isFunction} from '../../../services/helpers';
import styled from 'styled-components';
import {withTranslation, WithTranslation} from 'react-i18next';

export default withTranslation()(FormFooter);

export interface IFormFooterProps extends WithTranslation {
  cancelButtonText?: string;
  submitButtonText?: string;
  cancelButtonProps?: any;
  submitButtonProps?: any;
  onSubmit?: (...args: any[]) => any;
  onCancel?: (...args: any[]) => any;
  showCancelButton?: boolean;
  customButton?: () => React.ReactNode;
  disabledSubmitButton?: boolean;
}

const StyledRow = styled(Row)`
  border-top: ${({theme}) => theme.colors.borderColor} solid 1px;
`;

const StyledButton = styled(Button)`
  margin-left: auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;

  > * {
    margin-left: 10px;
  }
`;

function FormFooter({
  cancelButtonText = 'Cancel',
  submitButtonText = 'Submit',
  cancelButtonProps,
  submitButtonProps,
  onSubmit,
  onCancel,
  showCancelButton = true,
  customButton,
  disabledSubmitButton = false,
  t,
}: IFormFooterProps): JSX.Element {
  return (
    <StyledRow gutter={20}>
      <Col span={24}>
        <ButtonWrapper>
          {showCancelButton && (
            <Button
              onClick={() => onCancel && onCancel()}
              {...cancelButtonProps}>
              {t(cancelButtonText)}
            </Button>
          )}
          {isFunction(customButton) ? customButton() : null}
          <StyledButton
            disabled={disabledSubmitButton}
            style={{}}
            type="primary"
            htmlType="submit"
            onClick={() => onSubmit && onSubmit()}
            {...submitButtonProps}>
            {t(submitButtonText)}
          </StyledButton>
        </ButtonWrapper>
      </Col>
    </StyledRow>
  );
}
