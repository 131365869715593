import {ForgotPasswordDTO, LoginDTO, UserDTO, IUserDTOProps} from '../struture';
import {apiGet, apiPatch, apiPost, apiPut, TokenHandler} from './core';
import {authUrl} from './const';
import {ApiAnswer} from './types';
import {getCookie} from './helpers';

export interface IUserAuthorizeAnswer extends ApiAnswer {
  user: IUserDTOProps;
  access_token: string;
  required_redirect: string;
  refresh_token: string;
  invite_id: string;
}

export async function login(value: LoginDTO): Promise<IUserAuthorizeAnswer> {
  const res = await apiPost<LoginDTO, IUserAuthorizeAnswer>(
    `${authUrl}/auth/signin`,
    value,
    {
      ignoreRedirect: true,
    },
  );

  TokenHandler.setAuthTokens({
    refresh: res.refresh_token,
    access: res.access_token,
    is_verify_email: String(res?.user?.status),
  });

  TokenHandler.setRestoreFromCookiesStatus('1');

  return res;
}

export async function acceptPolicy(): Promise<IUserAuthorizeAnswer> {
  const token = TokenHandler.getAccessToken();

  const res = await apiPatch<null, IUserAuthorizeAnswer>(
    `${authUrl}/users/accept-terms-and-conditions`,
    null,
    {
      ignoreRedirect: true,
    },
    {Authorization: `Token ${token}`},
  );

  TokenHandler.setVerifyEmailStatus(String(res?.user?.status));

  return res;
}

export async function restore(value: ForgotPasswordDTO): Promise<void> {
  await apiPost<any, any>(`${authUrl}/users/restore`, value, {
    ignoreRedirect: true,
  });
}

export interface IGetProfileProps {
  user: UserDTO;
}

export async function logout(): Promise<void> {
  const token: any =
    TokenHandler.getRefreshToken() || getCookie('refresh_token');

  await apiPost<{token: string | null}, null>(`${authUrl}/token/revoke`, {
    token,
  });
}

export async function getProfile(): Promise<UserDTO> {
  const {user} = await apiGet<null, IGetProfileProps>(
    `${authUrl}/users/me`,
    null,
  );

  return new UserDTO(user);
}

export async function editProfile({
  name,
  surname,
  middlename,
  phone,
  address,
  birthday,
  gender,
  email,
}: UserDTO): Promise<UserDTO> {
  const {user} = await apiPut<Partial<UserDTO>, IGetProfileProps>(
    `${authUrl}/users/me`,
    {name, surname, middlename, phone, address, birthday, gender, email},
  );

  return new UserDTO(user);
}

export async function generateEmailCode(): Promise<UserDTO> {
  const {user} = await apiPost<Partial<UserDTO>, IGetProfileProps>(
    `${authUrl}/users/generate-confirmation-email`,
    {},
  );

  return new UserDTO(user);
}
