import {compose, split, eq, head} from './index';
import {List} from 'immutable';
import {app_languages} from '../types';
import {toLowerCase} from '@sportix/sportix-common-modules';
import moment, {Moment} from 'moment';

export const isUrl = (value: string): boolean => {
  const pattern = /^https?|data:image/;

  return !!value.match(pattern);
};

const isDote = (value: string) => eq(value, '.');
const isSlash = (value: string) => eq(value, '/');

export const isPath = (value: string): boolean => {
  return compose<boolean>(
    (value) => isDote(value) || isSlash(value),
    head,
    split(''),
  )(value);
};

export function getCookie(name: string): string | undefined {
  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' + name.replace(/([.$?*|{}()[]\\\/+^])/g, '\\$1') + '=([^;]*)',
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function setCookie(
  name: string,
  value: string,
  options: any = {},
): void {
  options = {
    path: '/',
    // при необходимости добавьте другие значения по умолчанию
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export function deleteCookie(name: string) {
  setCookie(name, '', {
    'max-age': -1,
  });
}

export const listToArray = <T>(value: List<T> | T[] | null): T[] | null =>
  List.isList(value) ? value.toArray() : value;

export const transformBrowserToHtmlLang = (lang: string): string => {
  if (lang === app_languages.Ukrainian) {
    return 'ua';
  }
  if (lang === app_languages.English) {
    return 'en';
  }

  if (lang === app_languages.Russian) {
    return 'ru';
  }
  return lang;
};

export const getContentLanguage = compose<string>(
  transformBrowserToHtmlLang,
  toLowerCase,
  head,
  split(','),
);

export const toMomentISOString = (date: string | Date | Moment): string =>
  moment(date).toISOString();

export const serializeInfo = (
  acc: string,
  [key, value]: [string, string | null],
): string => {
  const joinInfo = `${key}/${value}`;

  acc = acc ? `${acc};${joinInfo}` : joinInfo;

  return acc;
};

export const toMomentStringByFormat = (
  date: string | Date | Moment,
  format: string = 'YYYY-MM-DD',
): string => moment(date).format(format);

export const correctPrice = (price: string | number | undefined) =>
  parseFloat(`${price || 0}`).toFixed(2);
