import React, {useCallback, useEffect} from 'react';
import {reduce, serializeInfo} from '../../../services/helpers';
import {LocalStorageItems, appId} from '../../../services/const';

const platform = require('platform');
const pjson = require('../../../../package.json');

const appInfo = {
  'app-id': appId || '',
  'app-version': pjson?.version || '',
  'app-build-number': pjson?.version || '',
};

export function useAppInfo() {
  const getAppInfo = useCallback(() => {
    const serializeAppInfo = reduce(serializeInfo, '', Object.entries(appInfo));
    localStorage.setItem(LocalStorageItems.xAppInfo, serializeAppInfo);

    const deviceInfo = {
      'device-os': platform.os.toString() || '',
      'device-os-version': platform.version || '',
      'device-name': platform.name || '',
      'device-model-name': '',
    };

    const serializeDeviceInfo = reduce(
      serializeInfo,
      '',
      Object.entries(deviceInfo),
    );
    localStorage.setItem(LocalStorageItems.xDeviceInfo, serializeDeviceInfo);
  }, []);

  useEffect(() => {
    getAppInfo();
  }, [getAppInfo]);
}
