import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {FormInstance} from 'antd/lib/form';
import {ProfileFormButton} from '../Buttons';
import {Col, Row, Space} from 'antd';
import {EditOutlined, ReloadOutlined, CheckOutlined} from '@ant-design/icons';
import {Text} from '../../common/Format';
import {styleButton} from '../../common/General';
import styled from 'styled-components';

export default withTranslation()(ProfileFormActions);

export interface IProfileFormActionsProps
  extends WithTranslation,
    Pick<FormInstance, 'submit'> {
  editMode?: boolean;
  onCancel: () => void;
  onEdit: () => void;
  loading: boolean;
}

const StyledRow = styled(Row)`
  background-color: ${({theme}) => theme.colors.app};
  border-radius: ${({theme}) => theme.border.baseBorderRadius};
  padding: 20px 10px;
  margin-bottom: 20px;
`;

const StyledSuccessButton = styled(ProfileFormButton)`
  ${styleButton}
`;

const StyledCancelButton = styled(ProfileFormButton)`
  box-shadow: none;
  background: #464646;
  border: none;
  border-radius: ${({theme}) => theme.border.baseBorderRadius};

  &:hover,
  &:active,
  &:focus {
    box-shadow: 0 0 30px rgb(5 10 15 / 50%);
  }
`;

function ProfileFormActions({
  t,
  submit,
  editMode,
  onCancel,
  onEdit,
  loading,
}: IProfileFormActionsProps): JSX.Element {
  return (
    <StyledRow gutter={20}>
      <Col span={24}>
        {editMode ? (
          <Space>
            <StyledSuccessButton
              loading={loading}
              onSuccess={submit}
              size="middle">
              <Space>
                <CheckOutlined />
                <Text>{t('Save')}</Text>
              </Space>
            </StyledSuccessButton>
            <StyledCancelButton
              loading={loading}
              onSuccess={onCancel}
              size="middle">
              <Space>
                <ReloadOutlined />
                <Text>{t('Cancel')}</Text>
              </Space>
            </StyledCancelButton>
          </Space>
        ) : (
          <StyledSuccessButton
            loading={loading}
            onSuccess={onEdit}
            size="middle">
            <Space>
              <EditOutlined />
              <Text>{t('Edit')}</Text>
            </Space>
          </StyledSuccessButton>
        )}
      </Col>
    </StyledRow>
  );
}
