import React, {useState} from 'react';
import {Form, Row, Col} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {DefaultForm, IDefaultFormProps, Button} from '../../../common/General';
import {withTranslation, WithTranslation} from 'react-i18next';
import {ForgotPasswordFormDTO} from '../../../../struture';
import {Input} from '../../../common/DataEntry';
import styled from 'styled-components';

export default withTranslation()(ForgotPasswordForm);

export interface IForgotPasswordFormProps
  extends WithTranslation,
    Omit<
      IDefaultFormProps<ForgotPasswordFormDTO, ForgotPasswordFormDTO>,
      'children' | 'initialValues' | 'additionalValuesRequest'
    > {
  onCancel?: () => void;
  onSuccess: (value: ForgotPasswordFormDTO) => Promise<void>;
  onError?: () => Promise<void>;
  forgotPassword: ForgotPasswordFormDTO;
}

const StyledUserOutlined = styled(UserOutlined)`
  color: ${({theme}) => theme.colors.disable};
`;

function ForgotPasswordForm({
  forgotPassword,
  t,
  ...rest
}: IForgotPasswordFormProps): JSX.Element {
  const [isRestore, setIsRestore] = useState<boolean>(false);

  return (
    <DefaultForm
      initialValues={{...forgotPassword}}
      showFooter={false}
      {...rest}>
      {({loadingSubmit, setFieldsValue, submit, validateFields}) => (
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              // label={t('Email or Phone')}
              name="identifier"
              rules={[
                {
                  required: true,
                  message: t('Please input your Email or Phone!'),
                },
              ]}>
              <Input
                data-testid="forgot-password-form-input-email"
                disabled={loadingSubmit || isRestore}
                prefix={<StyledUserOutlined />}
                placeholder={t('Enter your email or phone')}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            {isRestore ? (
              <Button
                block
                type="primary"
                data-testid="forgot-password-form-not-received-button"
                onClick={() => {
                  setFieldsValue({identifier: ''});
                  setIsRestore(false);
                }}>
                {t('Haven`t received the email?')}
              </Button>
            ) : (
              <Button
                data-testid="reset-password-form-button-submit"
                loading={loadingSubmit}
                type="primary"
                onClick={() => {
                  validateFields().then(({identifier}) => {
                    if (identifier) {
                      setIsRestore(true);
                    }
                  });
                  submit();
                }}
                block>
                {t('Submit')}
              </Button>
            )}
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
