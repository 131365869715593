import React, {useRef} from 'react';
import {FlexContainer} from '../Styled';
import styled, {css} from 'styled-components';
import StatusTitle, {IStatusTitleProps} from './StatusTitle';
import {Space} from 'antd';
import {useSize} from '../hooks';

export interface ICardHeaderProps extends Omit<IStatusTitleProps, 'children'> {
  title: string;
  extra?: React.ReactNode[];
}

const StyledHeader = styled(FlexContainer)`
  width: 100%;
  height: 25%;
  background-color: ${({theme}) => theme.colors.disable};
  padding: 5px 10px;
  border-top-right-radius: ${({theme}) => theme.border.baseBorderRadius};
  border-top-left-radius: ${({theme}) => theme.border.baseBorderRadius};
`;

const ButtonFlexContainer = styled(FlexContainer)`
  width: auto;
`;

const StyledSpace = styled(Space)<{$wrap: boolean}>`
  flex-wrap: wrap !important;
  ${({$wrap}) =>
    $wrap &&
    css`
      & .ant-space {
        justify-content: center;

        & .ant-space-item:first-child {
          margin-top: 5px;
        }

        & .ant-space-item:last-child {
          width: 100%;
        }
      }
    `}
`;

export default function CardHeader({
  title,
  status,
  extra,
  statusPattern = '[]',
}: ICardHeaderProps): JSX.Element {
  const headerRef = useRef(null);
  const {width: headerWidth} = useSize(headerRef);

  return (
    <StyledHeader
      ref={headerRef}
      justifyContent={headerWidth <= 667 ? ' flex-start' : 'space-between'}
      alignItems="center"
      flexWrap="wrap">
      <StatusTitle statusPattern={statusPattern} status={status}>
        {title}
      </StatusTitle>
      {extra ? (
        <ButtonFlexContainer
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap">
          <StyledSpace $wrap={headerWidth <= 331}>{extra}</StyledSpace>
        </ButtonFlexContainer>
      ) : null}
    </StyledHeader>
  );
}
