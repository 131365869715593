import React from 'react';
import styled, {css} from 'styled-components';
import {withTranslation, WithTranslation} from 'react-i18next';
import {
  CheckCircleOutlined,
  StopOutlined,
  CloudSyncOutlined,
} from '@ant-design/icons';
import {FlexContainer} from '../Styled';
import {Text} from '../Format';
import {isFunction} from '../../../services/helpers';
import {
  PLAN_STATUS,
  PLAN_STATUS_TEXT,
  VALUES_PLAN_STATUS,
} from '../../../services/types';

export default withTranslation()(Status);

export interface IStatusProps extends WithTranslation {
  status: VALUES_PLAN_STATUS;
  className?: string;
  statusPattern?: string;
}

interface IStatus {
  status?: VALUES_PLAN_STATUS;
}

const statusStyle = css<IStatus>`
  color: ${({theme}) => theme.colors.primary};
  ${({status}) =>
    status === PLAN_STATUS.Active &&
    css`
      color: ${({theme}) => theme.activeButton.buttonPrimaryColor};
    `}
  ${({status}) =>
    (status === PLAN_STATUS.In_processing ||
      status === PLAN_STATUS.NotActivated) &&
    css`
      color: ${({theme}) => theme.colors.error};
    `}
`;

const StatusFlexContainer = styled(FlexContainer)`
  height: fit-content;
`;

const iconStyle = css`
  ${statusStyle};
  font-size: 0.9em;
  margin-right: 10px;
`;

const StyledCheckCircleOutlined = styled(CheckCircleOutlined)<IStatus>`
  ${iconStyle}
`;

const StyledStopOutlined = styled(StopOutlined)<IStatus>`
  ${iconStyle}
`;

const StyledCloudSyncOutlined = styled(CloudSyncOutlined)<IStatus>`
  ${iconStyle}
`;

const StyledText = styled(Text)<IStatus>`
  font-size: 0.9em;
  ${statusStyle}
`;

function Status({
  status,
  className,
  t,
  statusPattern = '',
}: IStatusProps): JSX.Element {
  const statusIcon: {
    [key in VALUES_PLAN_STATUS]: ({
      status,
    }: Pick<IStatusProps, 'status'>) => React.ReactNode;
  } = {
    [PLAN_STATUS.Active]: ({status}: Pick<IStatusProps, 'status'>) => (
      <StyledCheckCircleOutlined status={status} />
    ),
    [PLAN_STATUS.NotActivated]: ({status}: Pick<IStatusProps, 'status'>) => (
      <StyledStopOutlined status={status} />
    ),
    [PLAN_STATUS.In_processing]: ({status}: Pick<IStatusProps, 'status'>) => (
      <StyledCloudSyncOutlined status={status} />
    ),
    [PLAN_STATUS.Expired]: ({status}: Pick<IStatusProps, 'status'>) => (
      <StyledCloudSyncOutlined status={status} />
    ),
  };

  // const [firstPatter, secondPattern] = getStringPattern(statusPattern);

  return (
    <StatusFlexContainer
      className={className}
      alignItems="center"
      flexDirection="row">
      {/*<StyledText status={status}>{firstPatter}</StyledText>*/}
      {isFunction(statusIcon[status]) ? (
        <Text>{statusIcon[status]({status})}</Text>
      ) : null}
      <StyledText status={status}>
        {t(PLAN_STATUS_TEXT[status] || 'undefined')}
      </StyledText>
      {/*<StyledText status={status}>{secondPattern}</StyledText>*/}
    </StatusFlexContainer>
  );
}
