import React from 'react';
import {Row, Col} from 'antd';
import {Text} from '../../common/Format';
import styled from 'styled-components';
import {withTranslation, WithTranslation} from 'react-i18next';
import {APP_NAME, APP_TITLE} from '../../../services/const';
import {toLowerCase} from '../../../services/helpers';

export default withTranslation()(GreetingMessage);

export interface IGreetingMessageProps extends WithTranslation {}

const StyledRow = styled(Row)`
  background-color: ${({theme}) => theme.colors.app};
  padding: 20px;
  border-radius: ${({theme}) => theme.border.baseBorderRadius};
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

const StyledWelcomeText = styled(Text)`
  font-size: 20px;
`;

function GreetingMessage({t}: IGreetingMessageProps): JSX.Element {
  return (
    <StyledRow gutter={20}>
      <StyledCol span={24}>
        <StyledWelcomeText bold>
          {`${t('Welcome to the')} ${APP_NAME} ${toLowerCase('Console')} ( ${t(
            'beta',
          )} )`}
        </StyledWelcomeText>
        <Text>
          {`${t(
            'Your subscription and access to the',
          )} ${APP_NAME} ${toLowerCase('console')} ${t(
            'can be managed from this postal',
          )}`}
        </Text>
      </StyledCol>
    </StyledRow>
  );
}
