import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Collapse, Space} from 'antd';
import styled from 'styled-components';
import {FlexContainer} from '../Styled';
import {Text} from '../Format';

const {Panel} = Collapse;

export default withTranslation()(ClientSubscriptionCardCollapse);

export interface ICardCollapseProps extends WithTranslation {
  title: string;
  children: React.ReactNode;
}

const StyledAntCollapse = styled(Collapse)`
  width: 100%;

  & .ant-collapse-header {
    padding-left: 0 !important;
  }

  & .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

const StyledSpace = styled(Space)`
  width: 100%;
  & * {
    width: 100%;
  }
`;

const CollapseBody = styled(FlexContainer)`
  width: 95%;
  margin: 0 auto 10px auto;
  background-color: ${({theme}) => theme.colors.lightDisable};
  overflow: hidden;
  border-radius: ${({theme}) => theme.border.baseBorderRadius};
`;

const StyledPanel = styled(Panel)`
  & .ant-collapse-header > * {
    color: ${({theme}) => theme.colors.link};
  }

  & .ant-collapse-header {
    margin-left: 10px;
  }
`;

const StyledHeaderTitle = styled(Text)`
  color: ${({theme}) => theme.colors.link};
`;

function ClientSubscriptionCardCollapse({
  t,
  title,
  children,
}: ICardCollapseProps): JSX.Element {
  return (
    <StyledAntCollapse ghost>
      <StyledPanel
        header={<StyledHeaderTitle>{t(title)}</StyledHeaderTitle>}
        key="1">
        <CollapseBody flexDirection="column" key={'1'}>
          {children &&
            React.Children.map(children, (child) => (
              <StyledSpace>{child}</StyledSpace>
            ))}
        </CollapseBody>
      </StyledPanel>
    </StyledAntCollapse>
  );
}
