import React, {useCallback, useEffect, useRef} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {useStatePlanList, useInterval} from '../common/hooks';
import {Card} from '../common/DataDisplay';
import {Empty, Space} from 'antd';
import {Text} from '../common/Format';
import {
  SubscriptionModel,
  PlanListMapper,
  PlanFormDTO,
  PlanTariffFormDTO,
} from '../../struture';
import styled from 'styled-components';
import {LoadingLayout} from '../common/Layout';
import {SubscriptionActions} from './Show';
import {List} from 'immutable';
import {PLAN_STATUS} from '../../services/types';
import {
  ChangeSubscriptionTariffButton,
  StyledSubscriptionButton,
  SubscriptionButton,
} from './Buttons';
import {
  subscriptionActivate,
  subscriptionTransition,
} from '../../services/subscriptions';
import {sortedByDate, PLANS_TRIAL} from '../../services/helpers';

export default withTranslation()(SubscriptionPage);

export interface ISubscriptionPageProps extends WithTranslation {}

const StyledText = styled(Text)`
  padding: 10px 10px 0 10px;
`;

function SubscriptionPage({t}: ISubscriptionPageProps): JSX.Element {
  const {plans, loading, refresh} = useStatePlanList();

  const {intervalCallback, handleClearInterval} = useInterval(
    () => refresh({showLoading: false}),
    2000,
    15000,
  );

  const planEndDate = useRef<string | undefined>(undefined);

  const subscriptionContent = (
    subscription: SubscriptionModel,
  ): JSX.Element => (
    <Card.Content
      key={subscription.id}
      entity={{
        price: Number(subscription.price) > 0 ? subscription.price : t('Free'),
        periods: subscription.periods,
        discount: subscription.discount,
        date_end: subscription.toFormatEndDate(),
        date_start: subscription.toFormatStartDate(),
      }}
    />
  );

  const openLinkAndCancelCall = useCallback(
    async (url: string) => {
      window?.open(url, '_blank');

      await intervalCallback();
    },
    [intervalCallback],
  );

  const handlePayPlan = useCallback(
    async (value: PlanFormDTO): Promise<void> => {
      const url = await subscriptionActivate(value);
      await openLinkAndCancelCall(url);
    },
    [openLinkAndCancelCall],
  );

  const handleChangePlan = useCallback(
    async (value: PlanTariffFormDTO): Promise<void> => {
      const url = await subscriptionTransition(value);

      await openLinkAndCancelCall(url);
    },
    [openLinkAndCancelCall],
  );

  useEffect(() => {
    if (List.isList(plans) && !planEndDate.current) {
      planEndDate.current = plans
        ?.first()
        ?.subscriptions?.sort(sortedByDate('date_end'))
        .last()?.date_end;
    }
  }, [plans]);

  useEffect(() => {
    if (List.isList(plans)) {
      const updatedPlanEndDate = plans
        .first()
        ?.subscriptions?.sort(sortedByDate('date_end'))
        .last()?.date_end;

      if (updatedPlanEndDate !== planEndDate.current) {
        handleClearInterval();
        planEndDate.current = updatedPlanEndDate;
      }
    }
  }, [handleClearInterval, plans]);

  return (
    <LoadingLayout loading={loading}>
      {List.isList(plans) && plans.size ? (
        plans?.map((plan) => (
          <React.Fragment key={plan?.id}>
            <Card
              title={t(plan?.identifier)}
              status={plan?.status}
              extra={[
                <React.Fragment key={'1'}>
                  <Space style={{flexWrap: 'wrap'}} size="small">
                    <SubscriptionActions key="actions" status={plan?.status} />
                    <ChangeSubscriptionTariffButton
                      title={t('Change the tariff')}
                      subscription={PlanListMapper.toPlanTariffFormDTO(plan)}
                      onSuccess={handleChangePlan}
                    />
                  </Space>
                </React.Fragment>,
              ]}>
              <StyledText bold>{`${t('Last subscription')}:`}</StyledText>

              {plan.isEmptyFirstSubscription()
                ? null
                : plan.getFirstSubscription().map(subscriptionContent)}

              {plan.isEmptySubscriptionList() ? null : (
                <Card.Collapse title={'Subscriptions'}>
                  {plan.getRestSubscription().map(subscriptionContent)}
                </Card.Collapse>
              )}
            </Card>
            {plan?.status === PLAN_STATUS.Active ||
            plan?.status === PLAN_STATUS.Expired ? (
              plan.identifier === PLANS_TRIAL ? null : (
                <SubscriptionButton
                  title={t('Prolong')}
                  subscription={PlanListMapper.toPlanFormDTO(plan)}
                  onSuccess={handlePayPlan}
                />
              )
            ) : (
              <StyledSubscriptionButton>
                {t('Activate')}
              </StyledSubscriptionButton>
            )}
          </React.Fragment>
        ))
      ) : (
        <Empty description={<Text>{t('No subscription found')}</Text>} />
      )}
    </LoadingLayout>
  );
}
