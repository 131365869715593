import React from 'react';
import {Layout} from 'antd';
import styled from 'styled-components';

const {Content} = Layout;

export interface IWithoutLayout {
  children: React.ReactNode;
}

const StyledLayout = styled(Layout)`
  min-height: 100vh;
  background-color: ${({theme}) => theme.colors.app};
`;

const StyledContent = styled(Content)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default function WithoutLayout({children}: IWithoutLayout): JSX.Element {
  return (
    <StyledLayout data-testid="app-layout">
      <StyledContent data-testid="app-content">{children}</StyledContent>
    </StyledLayout>
  );
}
