import {Record} from 'immutable';
import {ProfileDTO} from '../dto';
import {
  firstLetterToUppercase,
  getFirstLatterToUpperCase,
  toMoment,
} from '../../services/helpers';
import {GENDER} from '../../services/types';
import {Moment} from 'moment';

export interface IProfileModelReturnType {
  isAdmin: boolean;
  firstName: string;
  lastName: string;
  middleName: string;
  uuid: string;
  status: number;
  phone: string;
  phoneApproved: boolean;
  email: string;
  emailApproved: boolean;
  pictureUrl: string;
  address: string;
  birthday: string;
  gender: GENDER;
}

export class ProfileModel extends Record<IProfileModelReturnType>({
  isAdmin: false,
  firstName: '',
  lastName: '',
  middleName: '',
  uuid: '',
  status: 0,
  phone: '',
  phoneApproved: false,
  email: '',
  emailApproved: false,
  pictureUrl: '',
  address: '',
  birthday: '',
  gender: GENDER.MALE,
}) {
  constructor(props = {} as ProfileDTO) {
    const options: IProfileModelReturnType = {
      isAdmin: props?.is_admin,
      firstName: props?.first_name,
      lastName: props?.last_name,
      middleName: props?.middle_name,
      uuid: props?.uuid,
      status: props?.status,
      phone: props?.phone,
      phoneApproved: !!props?.phone_approved,
      email: props?.email,
      emailApproved: !!props?.email_approved,
      pictureUrl: props?.picture_url,
      address: props?.address,
      birthday: props?.birthday,
      gender: props?.gender,
    };
    super(options);
  }

  get isAdminProfile(): boolean {
    return this.isAdmin;
  }

  get fullName(): string {
    return `${firstLetterToUppercase(this.lastName)} ${firstLetterToUppercase(
      this.firstName,
    )} `;
  }

  get shortFullName(): string {
    return `${firstLetterToUppercase(
      this.lastName,
    )} ${getFirstLatterToUpperCase(this.firstName)}. ${
      this.middleName ? `${getFirstLatterToUpperCase(this.middleName)}.` : ''
    }`;
  }

  get isFullName(): boolean {
    return !!this.firstName && !!this.lastName;
  }

  get birthdayToMoment(): Moment {
    return toMoment(this.birthday);
  }
}
