import * as React from 'react';
import i18n from 'i18next';

export interface IUseLanguageReturnType {
  changeLanguage: (language: string) => Promise<void>;
}

export default function useLanguage(): IUseLanguageReturnType {
  const changeLanguage = React.useCallback(async (language: string) => {
    const lang = localStorage.getItem('language');

    if (language && lang !== language) {
      await i18n.changeLanguage(language);
      localStorage.setItem('language', language);
    }
  }, []);

  return {
    changeLanguage,
  };
}
