export const authUrl =
  process.env.REACT_APP_USER_AUTH_SERVICE_URL ||
  'https://sportix-auth-api.herokuapp.com/api';

export const appAuthUrl =
  process.env.REACT_MAIN_APP_URL || 'https://app.servicity.in.ua/app';

export const APP_TITLE =
  process.env.REACT_APP_APP_TITLE || '/servicity-logo-white-ua.png';

export const APP_NAME = process.env.REACT_APP_APP_NAME || 'Servicity';

export const authLogoUrl = process.env.AUTH_LOGIN_LOGO || '/servicity-logo.svg';

export const authSiteUrl =
  process.env.AUTH_SITE_URL || 'https://app.servicity.in.ua/';

export const authLogoTitle = process.env.AUTH_SITE_TITLE || 'CRM ServicitY';

export const contentApiUrl =
  process.env.REACT_APP_CONTENT_SERVICE_URL ||
  'https://sportix-api.herokuapp.com/api';

export const domainUrl = process.env.DOMAIN_APP_URL || 'servicity.in.ua';

export const domainAuthUrl =
  process.env.DOMAIN_AUTH_APP_URL || 'https://servicity.in.ua/#auth';

export const thankYouDescription =
  process.env.REACT_APP_THANK_YOU_DESCRIPTION || 'Description';

export const thankYouTitle =
  process.env.REACT_APP_THANK_YOU_TITLE || 'Thank you.';

export const googleAnalyticsTrackingId =
  process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || 'your tracking id';

export const footerAppName = process.env.REACT_APP_FOOTER_APP_NAME || 'Console';

export const appId =
  process.env.REACT_APP_ID || 'com.sergeydonchenko.servicity';

export enum LocalStorageItems {
  xAppInfo = 'x-app-info',
  xDeviceInfo = 'x-device-info',
  language = 'language',
}

export const AcceptLanguageItem: any = {
  ua: 'uk-UA,uk;q=0.5',
  ru: 'ru;q=0.8',
  en: 'en-US;q=0.7,en;q=0.6',
};

export const desktopScreen = 1200;
export const smallTableScreen = 1024;
export const bigTableScreen = 960;
export const tableScreen = 768;
export const bigPhoneScreen = 580;
export const phoneScreen = 480;
