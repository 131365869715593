import React from 'react';
import {Tooltip} from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import {FlexContainer, IFlexContainerProps} from '../Styled';
import {Text, ITextProps} from '../Format';

export interface ILabelProps extends IFlexContainerProps {
  tooltipTitle?: string;
  label?: string;
  children?: React.ReactNode;
  labelProps?: Omit<ITextProps, 'children'>;
}

const LabelFlexContainer = styled(FlexContainer)<{invert?: number}>`
  width: fit-content;

  & .label-text__title {
    margin-bottom: 8px;
  }

  & .label-text__label {
    color: black;
  }

  & .label-text__tooltip {
    margin-left: 5px;
    color: rgba(0, 0, 0, 0.45) !important;
  }
  & .label-text__tooltip:hover {
    cursor: help;
  }

  & .label-text {
    margin-bottom: 4px;
  }
`;

export default function Label({
  tooltipTitle,
  label,
  children,
  labelProps = {},
  ...rest
}: ILabelProps): JSX.Element {
  return (
    <LabelFlexContainer flexDirection="column" {...rest}>
      <div className="label-text">
        {label && (
          <Text className="label-text__title" {...labelProps}>
            {label}
          </Text>
        )}
        {tooltipTitle && (
          <Tooltip title={tooltipTitle} className="label-text__tooltip">
            <QuestionCircleOutlined size={24} />
          </Tooltip>
        )}
      </div>
      {children}
    </LabelFlexContainer>
  );
}
