import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import AuthRoute from './AuthRoute';
import {LoginPage, ForgotPasswordPage} from '../../components/Auth';
import {Routes as RoutesType} from '../../services/types';
import {DashboardPage} from '../../components/Dashboard';
import {SubscriptionPage} from '../../components/Subscription';
import {ProfilePage} from '../../components/Profile';
import {ThankYouPage} from '../../components/ThankYou';
import {
  TermsAndConditionsPage,
  PrivacyAndGDPRSPage,
} from '../../components/Terms&Policy';
import {TokenHandler} from '../../services/core';
import {getCookie} from '../../services/helpers';

export default Routes;

const access_token_local = TokenHandler.getAccessToken();
const is_verify_email_local = TokenHandler.getVerifyEmailStatus();
const is_verify_email_cookie = getCookie('is_verify_email');
const access_token_cookie = getCookie('access_token');

function Routes() {
  return (
    <Router>
      <Switch>
        <Redirect
          exact
          from="/"
          to={
            (access_token_local || access_token_cookie) &&
            (is_verify_email_local === '1' || is_verify_email_cookie === '1')
              ? '/app'
              : '/auth'
          }
        />

        <Route exact path={`/${RoutesType.thankYou}`}>
          <ThankYouPage />
        </Route>

        <Route exact path={`/${RoutesType.termsAndConditions}`}>
          <TermsAndConditionsPage />
        </Route>

        <Route exact path={`/${RoutesType.privacyAndGDPRS}`}>
          <PrivacyAndGDPRSPage />
        </Route>

        <Route path={`/${RoutesType.auth}`}>
          <AuthRoute>
            {({path}) => (
              <Switch>
                <Redirect
                  exact
                  from={path}
                  to={`${path}/${RoutesType.login}`}
                />
                <AuthRoute.MainLayout
                  exact
                  path={`${path}/${RoutesType.login}`}>
                  <LoginPage />
                </AuthRoute.MainLayout>
                <AuthRoute.MainLayout
                  exact
                  path={`${path}/${RoutesType.forgotPassword}`}>
                  <ForgotPasswordPage />
                </AuthRoute.MainLayout>
              </Switch>
            )}
          </AuthRoute>
        </Route>

        <Route path={`/${RoutesType.app}`}>
          <PrivateRoute>
            {({path}) => (
              <Switch>
                <Redirect
                  exact
                  from={path}
                  to={`${path}/${RoutesType.dashboard}`}
                />

                {/*Base*/}

                <PrivateRoute.MainLayout
                  path={`${path}/${RoutesType.dashboard}`}>
                  <DashboardPage />
                </PrivateRoute.MainLayout>

                <PrivateRoute.MainLayout
                  path={`${path}/${RoutesType.subscription}`}>
                  <SubscriptionPage />
                </PrivateRoute.MainLayout>

                <PrivateRoute.MainLayout path={`${path}/${RoutesType.profile}`}>
                  <ProfilePage />
                </PrivateRoute.MainLayout>
              </Switch>
            )}
          </PrivateRoute>
        </Route>
      </Switch>
    </Router>
  );
}
