import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';

export default withTranslation()(PrivacyAndGDPRSPage);

export interface IPrivacyAndGDPRSProps extends WithTranslation {}

function PrivacyAndGDPRSPage({t}: IPrivacyAndGDPRSProps): JSX.Element {
  return (
    <div>
      <h1 className="title">{t('Servicity Privacy and GDPRS')}</h1>
      <h3 className="title sub-title">General terms</h3>
      <p className="paragraph">
        <ul>
          <li>
            These General Terms are applicable to the Agreement between the
            Customer and the Company and set out the terms under which the
            Company shall provide the Services to the Customer.
          </li>
          <li>
            The Customer enters into the Agreement with the Company by filling
            out the relevant signup form located at the Company’s Web Site and
            by accepting these General Terms and any other terms applicable to
            the Agreement, as appropriate.
          </li>
          <li>
            The use of the Services is at all times subject to the Agreement.
          </li>
        </ul>
      </p>

      <h3 className="title sub-title">Definitions</h3>
      <p className="paragraph">
        <ul>
          <li>
            “Agreement” shall mean the agreement between the Company and the
            Customer, consisting of the above General Terms and any other terms
            applicable to the Services;
          </li>
          <li>
            “Services” shall mean the web-based SaaS services provided (branded
            ProJs Ninja) by the Company to the Customer, as set out in the
            Agreement;
          </li>
          <li>
            “Company” shall mean ProJs AB (Business ID: 556839-2897), a company
            duly registered under the laws of Sweden, having its principal place
            of business at Knipuddevägen 18, SE-394 77 Kalmar, Sweden;
          </li>
          <li>
            “Customer” shall mean the company or individual entering into the
            Agreement with the Company;
          </li>
          <li>
            “Intellectual Property Rights” shall mean any and all patents,
            utility models, design rights, copyrights (including the right to
            amend, modify, develop and assign), trademarks, trade names,
            inventions, trade secrets, domain names, know-how and any other
            industrial or intellectual property rights (including applications
            thereof);
          </li>
          <li>
            “Parties” and “Party” shall mean the Company and the Customer
            jointly and separately;
          </li>
          <li>
            “Subscription Fee(s)” means the compensation paid by the Customer
            for access to and use of the Services;
          </li>
          <li>
            “Web Site” shall mean the Company’s web site located at
            <a href="https://sevicity.in.ua">https://sevicity.in.ua</a>
          </li>
          <li>
            “Application” shall mean the Company’s Services website located at
            <a href="https://sevicity.in.ua">https://sevicity.in.ua</a>
          </li>
        </ul>
      </p>

      <h3 className="title sub-title">Use of services</h3>
      <p className="paragraph">
        <ul>
          <li>
            The Customer may use our services, provided that they have the
            authority to form a binding contract and are not barred from
            receiving such services under applicable local laws or
            jurisdictions.
          </li>
          <li>
            The Customer may access our services but will be required to provide
            current and factual identification, contact, and other information
            as part of the Web Site registration process.
          </li>
          <li>
            The Customer is solely responsible for all activities which occur
            within the Application and agrees to immediately notify the Company
            of any unauthorized use of their account or any other breach of
            security. The Company will not be liable for any loss or damage
            which results due to a failure of the Customer to provide us with
            accurate information or to keep your account secure.
          </li>
          <li>
            The service descriptions setting out the functionalities and
            features of the Services have been set out at the Company’s Web
            Site. The Company shall provide Services to the Customer
            substantially as set out in the service documentation.
          </li>
          <li>
            The Customer may order Services from the Company subject to the
            following:
            <li>
              Subscriptions may be made by the Customer at the Company’s Web
              Site or at any other site owned and operated by the Company
            </li>
            <li>
              The Customer shall be responsible for any subscriptions made under
              the Customer’s account, whether by the Customer itself, by its
              authorized users, and shall be responsible for the payment of all
              Fees based on orders made under the Customer’s account;
            </li>
            <li>
              After completing the subscription, the Company shall provide
              Services to the Customer without undue delay after the Customer’s
              order.
            </li>
            <li>
              The Company reserves the right to make changes to the Services at
              any time.
            </li>
          </li>
        </ul>
      </p>

      <h3 className="title sub-title">Customer's obligations</h3>
      <p className="paragraph">
        <ul>
          <li>
            The Customer shall be responsible for paying any recurring
            Subscription Fees properly due in accordance with the Agreement.
          </li>
          <li>
            The Customer shall be responsible for its own devices, systems,
            applications, connections and software used to access the Services.
          </li>
          <li>
            The Customer shall be responsible for the protection of their
            customer’s data communications and data systems and costs for
            communications and other comparable costs related to the use of the
            Services.
          </li>
          <li>
            The Customer may authorize users to use the Services under the
            Customer’s account. The Customer shall ensure that any users it
            authorizes to use the Services under the Customer’s account comply
            with these Terms of Service at all times and use the Services only
            in accordance with the Agreement. The Customer shall be responsible
            for any use of the Services under the Customer’s account.
          </li>
          <li>
            The Customer shall be responsible for ensuring that its authorized
            users maintain their usernames and passwords diligently and not
            disclose them to third parties. The Customer undertakes to inform
            the Company without delay if any password has been revealed to a
            third party or if the Customer has a reason to suspect misuse of a
            username or password.
          </li>
          <li>
            The Customer shall change the password required for the use of the
            Services upon request of the Company if necessary due to data
            security risk to the Services.
          </li>
          <li>
            The Company (and its subcontractors where appropriate) reserve the
            right but assume no obligation to review the Customer’s use of the
            Services, including the Customer’s data to ensure compliance with
            the acceptable use policy and to discontinue any use by the Customer
            of the Services and removing any infringing data of the Customer.
            The Company (and its subcontractors where appropriate) may report
            suspected infringing activities to officials, regulators or other
            appropriate third parties, including the disclosure of appropriate
            information regarding the Customer.
          </li>
          <li>
            If the Customer becomes aware that its use of the Services infringes
            the policy set out herein, the Customer agrees to immediately cease
            the infringing use of the Services without separate notice. The
            Customer agrees to comply with any requests of the Company as
            regards to the ceasing of any use of the Services that infringes the
            policies set out herein.
          </li>
        </ul>
      </p>

      <h3 className="title sub-title">Prohibited usage</h3>
      <p className="paragraph">
        <ul>
          <li>
            The Customer agrees that they will not engage in any of the
            activities listed below which are considered prohibited usage and
            may result in immediate account suspension or cancellation without
            refund.
            <li>
              to violate, or encourage the violation of, the legal rights
              (including without limitation Intellectual Property Rights) of
              others;
            </li>
            <li>to engage in, promote or encourage any illegal activity;</li>
            <li>
              for any unlawful, invasive, infringing, defamatory, offensive,
              harmful or fraudulent purpose (for example, this may include
              phishing, creating a pyramid scheme or mirroring a website);
            </li>
            <li>
              to intentionally distribute viruses, worms, Trojan horses,
              corrupted files, hoaxes, or other items of a destructive or
              deceptive nature;
            </li>
            <li>
              to violate security or integrity of any network, computer or
              communications system, software application or network or
              computing device (such violations to include without limitation
              unauthorized access, interception of data or traffic or
              falsification of origin);
            </li>
            <li>
              to make connections to any users, hosts or networks unless the
              Customer has permission to communicate with them (such network
              abuses to include without limitation monitoring or crawling,
              denial of service attacks, intentional interference, operating
              open proxies, open mail relays or open recursive domain name
              servers, or to avoid system restrictions);
            </li>
            <li>
              to interfere with the use of the Services, or the equipment used
              to provide Services, by others;
            </li>
            <li>
              to disable, interfere with or circumvent any aspect of the
              Services;
            </li>
            <li>
              to use the Services, or any interfaces provided with the Services,
              to access any other product or service of the Company or its
              subcontractors in a manner that violates their applicable terms of
              service.
            </li>
            <li>
              by providing false data when registering for the Application,
              including fraudulent use of credit card numbers.
            </li>
            <li>
              Other activities viewed as Illegal or harmful such as engaging in
              illegal activities or engaging in activities harmful to the
              operations of the Company or its Customers.
            </li>
          </li>
        </ul>
      </p>

      <h3 className="title sub-title">
        Suspension, Discontinuation, or Termination of Service
      </h3>
      <p className="paragraph">
        <ul>
          <li>
            The Agreement shall remain in force until terminated by a Party. The
            Customer shall have the right to terminate the Services at any time.
          </li>
          <li>
            Upon the termination of the Agreement for any reason, any Fees owed
            by the Customer for any use of the Services until the effective date
            of the termination shall become immediately due.
          </li>
          <li>
            The Company shall have the right to suspend the provision of the
            Services for a reasonable period of time if this is necessary in
            order to perform installation, change or maintenance work in respect
            of the Services or if such suspension results from installation,
            change or maintenance work in respect of public communication
            networks.
          </li>
          <li>
            The Company shall also have the right to suspend the provision of
            the Services and/or deny the Customer’s access to the Services
            without first hearing the Customer due to a data security risk to
            the Services or if law or administrative order requires the Company
            to do so or if the Company becomes aware of or reasonably suspects
            any activities of the Customer or its authorized users that infringe
            on the policies set out in these Terms of Service or if the Services
            are used contrary to, or for a purpose prohibited by the Agreement,
            applicable laws or administrative orders or in a manner that
            jeopardizes the provision of the Services to other users .
          </li>
          <li>
            The Company shall always have the right to suspend the Services, if
            the Customer is in default with its payment of the Subscription Fees
            due under the Agreement. The suspension can be continued until the
            Customer has paid all Fees due under the Agreement.
          </li>
          <li>
            The Customer understands that the Services are hosted by a
            subcontractor of the Company chosen by the Customer from the options
            provided by the Company. Such subcontractors may reserve rights to
            discontinue their hosting at any time. The Company shall not be
            liable in any way for any discontinuation of such services provided
            by subcontractors.
          </li>
        </ul>
      </p>

      <h3 className="title sub-title">Subscription Fees</h3>
      <p className="paragraph">
        <ul>
          <li>
            The rates applicable for the Services may be reviewed at the
            Company’s Web Site.
          </li>
          <li>
            The Company uses a credit card processing service provided by Stripe
            Payments Europe, Ltd. (https://stripe.com/) (“ Stripe”) to process
            its payments. The Customer consents to the use of the Stripe service
            and to the transfer of its credit card details (including any
            personal data contained therein) to Stripe. The Customer is familiar
            and agrees to be bound by any third party terms applicable to the
            Stripe service.
          </li>
          <li>
            The Subscription Fees for the use of the Services shall be charged
            via Stripe monthly. Unless otherwise agreed to between the Customer
            and Company.
          </li>
          <li>
            The Company reserves the right to increase the rates applicable to
            the Services where this is justified due to the general increase of
            the Company’s costs and expenses of production (such as but not
            limited to general increase of labor costs and expenses), or in the
            case of increase of costs of third party offerings. The Company
            shall inform the Customer of such change at least thirty (30) days
            before the effective date of the change. In such a case the Customer
            shall have the right to terminate the Agreement at that time.
          </li>
          <li>
            All rates and Fees are set out without value added tax (VAT) or any
            other applicable sales tax, which shall be added to the rates and
            Fees in accordance with the then-applicable tax laws and
            regulations.
          </li>
        </ul>
      </p>

      <h3 className="title sub-title">Personal Data and Customer Data</h3>
      <p className="paragraph">
        <ul>
          <li>
            All facilities used to store and process the Customer’s data will
            adhere to reasonable security standards no less protective than the
            security standards at facilities where the Company (or its
            subcontractor as relevant) processes and stores its own information
            of a similar type.
          </li>
          <li>
            To the extent the Customer inputs any personal data in to the
            Services, the Company (or its subcontractor where appropriate)
            processes such data on behalf and for the benefit of the Customer
            while the Customer remains at all times the data controller for such
            personal data. The Company processes the personal data submitted by
            the Customer to the Company in accordance with the Customer’s
            instructions and applicable data protection legislation.
          </li>
          <li>
            The Company implements appropriate technical and organizational
            measures to secure the Customer’s personal data; such measures
            include implementing reasonable and sufficient confidentiality
            obligations.
          </li>
          <li>
            The Company shall assist the Customer in complying with its
            obligations under applicable data protection laws, including
            assisting in responding to requests by data subjects and supervisory
            authorities to the extent reasonably necessary. The Company shall
            notify the Customer of any data breaches concerning personal data.
            The Company shall be entitled to charge for any reasonable costs and
            expenses incurred as a result of such assistance.
          </li>
          <li>
            The Company shall make available to the Customer information
            reasonably necessary to demonstrate compliance with the applicable
            data protection laws and contribute to audits conducted by the
            Customer or its representative in relation to the processing of
            personal data by the Company. The Company shall be entitled to
            charge for any reasonable costs and expenses incurred to Company.
          </li>
          <li>
            The Customer understands, accepts and consents to that the
            Customer’s data may be provided to the Company’s subcontractors for
            the purposes of providing the Services and for securing and
            improving their services as well as other purposes set out in this
            Agreement. The Company shall reasonably ensure that such
            subcontractors are subject to equivalent requirements regarding
            confidentiality and data protection, as those set out in this
            Agreement.
          </li>
          <li>
            The Company may also process data (or have such data processed) as
            regards the Customer and its authorized users, for the purpose of
            providing and developing the Service. In such case the Company may
            also be regarded as a data controller and such processing of
            Customer’s or its authorized users’ personal data shall be subject
            to the Company’s privacy policy as available on the Company’s Web
            Site. The Customer shall ensure that its authorized users may access
            the Company’s privacy policy.
          </li>
        </ul>
      </p>

      <h3 className="title sub-title">Intellectual Property Rights</h3>
      <p className="paragraph">
        <ul>
          <li>
            All rights, title and interest, including all Intellectual Property
            Rights in and to the Services and any changes thereto shall belong
            exclusively to the Company or its licensors. Except for the express
            license to use the Services granted to the Customer under and in
            accordance with the terms and conditions of the Agreement, the
            Customer shall have no and shall not by virtue of the Agreement
            obtain any rights, license or interests in and to the Services or
            any Intellectual Property Rights pertaining thereto.
          </li>
          <li>
            The Customer shall have a limited non-exclusive, non-transferable,
            non-sublicensable right to use the Services during the term of the
            Agreement.
          </li>
        </ul>
      </p>

      <h3 className="title sub-title">Indemnification</h3>
      <p className="paragraph">
        <ul>
          <li>
            This section sets out the entire liability of the Company and the
            Customer’s sole remedy in case of any infringement of any
            Intellectual Property Rights.
          </li>
          <li>
            The Company agrees to defend the Customer, at the Company’s own
            expense, against any third party claims or actions where a third
            party claims that the Services infringe upon Intellectual Property
            Rights of a third party valid in the European Economic Area (EEA),
            provided that the Customer:
            <li>
              notifies the Company of such claim immediately upon receipt of
              notice thereof;
            </li>
            <li>
              provides the Company, free of charge, with all the available
              information, permissions and assistance;
            </li>
            <li>
              grants the Company the sole and exclusive right to control the
              defense of the claim; and
            </li>
            <li>
              does not agree on any settlement of such claim or action prior to
              a final judgment thereon by a competent court of law or court of
              arbitration, without the express prior written consent of the
              Company.
            </li>
            <li>
              If the Customer has acted in accordance with the above, the
              Company shall pay any damages finally awarded to the third party
              claimant by a competent court of law or court of arbitration.
            </li>
            <li>
              The indemnity in this section shall not apply to, and the Company
              is not liable for any claim that (a) is based on a claim by any
              Customer Affiliate; or (b) is based on the modification or
              alteration of the Services or a modification or alteration
              influencing the Services by the Customer or any third party; or
              (c) results from complying with any instructions, specifications
              or design given by the Customer or any third party under the
              command and control of the Customer; (d) arises or results from
              the use of the Services in combination with any software,
              equipment or products not developed or supplied by the Company or
              which are contrary to instructions given by the Company; or (e)
              could have been avoided by using the latest version of the
              Services made available by the Company to the Customer.
            </li>
            <li>
              The Customer agrees to indemnify the Company, at the Customer’s
              own expense, against any claims made towards the Company based on
              any information or data the Customer inputs to the Services,
              including without limitation claims that the Customer’s data
              infringes third party intellectual property rights or that the
              data otherwise infringes applicable laws.
            </li>
          </li>
        </ul>
      </p>

      <h3 className="title sub-title">Warranty Disclaimer</h3>
      <p className="paragraph">
        <ul>
          <li>
            THE SERVICES AND CONTENT ARE PROVIDED “AS IS”, “AS AVAILABLE” AND
            WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT
            LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT,
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY
            WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE,
            ALL OF WHICH ARE EXPRESSLY DISCLAIMED. WE, AND OUR DIRECTORS,
            EMPLOYEES, AGENTS, SUPPLIERS, PARTNERS AND CONTENT PROVIDERS DO NOT
            WARRANT THAT: (I) THE SERVICES WILL BE SECURE OR AVAILABLE AT ANY
            PARTICULAR TIME OR LOCATION; (II) ANY DEFECTS OR ERRORS WILL BE
            CORRECTED; (III) ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE
            SERVICES IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (IV) THE
            RESULTS OF USING THE SERVICES WILL MEET YOUR REQUIREMENTS. YOUR USE
            OF THE SERVICES IS SOLELY AT YOUR OWN RISK.
          </li>
        </ul>
      </p>

      <h3 className="title sub-title">Limitation of Liability</h3>
      <p className="paragraph">
        <ul>
          <li>
            The total aggregate liability of a Party towards the other Party
            under the Agreement shall not exceed (i) an amount corresponding the
            average monthly fee of the six (6) months preceding the event giving
            rise to the liability multiplied by six (6), or (ii) fifty thousand
            (50.000) Euro, whichever is less.
          </li>
          <li>
            A Party shall not be liable for any indirect, incidental, or
            consequential damages such as loss of profits, revenue or business,
            damages caused due to decrease in turnover or production or loss,
            alteration, destruction or corruption of data.
          </li>
          <li>
            The limitations of liability shall not apply to damages caused by
            willful misconduct or gross negligence or to liability under these
            Terms of Service.
          </li>
          <li>
            IN NO EVENT SHALL WE, NOR OUR DIRECTORS, EMPLOYEES, AGENTS,
            PARTNERS, SUPPLIERS OR CONTENT PROVIDERS, BE LIABLE UNDER CONTRACT,
            TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR EQUITABLE
            THEORY WITH RESPECT TO THE SERVICES (I) FOR ANY LOST PROFITS, DATA
            LOSS, DATA THEFT, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR
            SERVICES, OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, COMPENSATORY
            OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER, SUBSTITUTE GOODS OR
            SERVICES (HOWEVER ARISING), (II) FOR ANY BUGS, VIRUSES, TROJAN
            HORSES, OR THE LIKE (REGARDLESS OF THE SOURCE OF ORIGINATION), OR
            (III) FOR ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) OF FEES
            PAID TO US FOR THE PARTICULAR SERVICES DURING THE IMMEDIATELY
            PREVIOUS ONE MONTH PERIOD, EVEN IF OTTOMATIK HAD BEEN ADVISED OF,
            KNEW, OR SHOULD HAVE KNOWN, OF THE POSSIBILITY THEREOF. SUBSCRIBER
            ACKNOWLEDGES THAT THE FEES PAID BY HIM OR HER REFLECT THE ALLOCATION
            OF RISK SET FORTH IN THIS AGREEMENT AND THAT OTTOMATIK WOULD NOT
            ENTER INTO THIS AGREEMENT WITHOUT THESE LIMITATIONS. SUBSCRIBER
            HEREBY WAIVES ANY AND ALL CLAIMS AGAINST OTTOMATIK ARISING OUT OF
            SUBSCRIBER'S PURCHASE OR USE OF THE SERVICES, OR ANY CONDUCT OF
            OTTOMATIK’S DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR
            REPRESENTATIVES. YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF
            DISSATISFACTION WITH THE SERVICES OR ANY OTHER GRIEVANCE SHALL BE
            YOUR TERMINATION AND DISCONTINUATION OF ACCESS TO OR USE OF THE
            SERVICES.
          </li>
        </ul>
      </p>

      <h3 className="title sub-title">Governing Law and Disputes</h3>
      <p className="paragraph">
        <ul>
          <li>
            The Agreement shall be governed by and construed in accordance with
            the substantive laws of Sweden, excluding its choice of law
            provisions.
          </li>
          <li>
            Any dispute, controversy or claim arising out of or relating to this
            Agreement, or the breach, termination or validity thereof, shall be
            finally settled by arbitration. The number of arbitrators shall be
            one. The seat of arbitration shall be Stokholm, Sweden. The language
            of the arbitration shall be English.
          </li>
          <li>
            Notwithstanding anything set out in these Terms of Service, the
            Company shall have the right to claim unpaid Fees in a public court.
          </li>
        </ul>
      </p>

      <h3 className="title sub-title">Other Terms</h3>
      <p className="paragraph">
        <ul>
          <li>
            The Company shall be free to use subcontractors in the performance
            of its obligations and exercise its rights under the Agreement. The
            Company shall be liable for the acts and omissions of its
            subcontractors under the Agreement as for its own.
          </li>
          <li>
            The Company shall have the right to use its relationship with the
            Customer in its marketing and sales promotion activities.
          </li>
          <li>
            Neither Party shall be entitled to assign nor transfer all or any of
            its rights, benefits and obligations under the Agreement without the
            prior written consent of the other Party, which consent shall not be
            unreasonably withheld or delayed. The Company shall, however have
            the right to assign the Agreement in connection with a sale or
            transfer of its business or a relevant part thereof.
          </li>
          <li>
            The Company shall have the right to update the General Terms at its
            sole discretion. Should the Customer not accept the updated Terms of
            Service, the Customer shall have the right to terminate the
            Agreement.
          </li>
          <li>
            Any terms and conditions that by their nature or otherwise
            reasonably should survive a cancellation or termination of the
            Agreement shall also be deemed to survive.
          </li>
        </ul>
      </p>
    </div>
  );
}
