import React from 'react';
import styled from 'styled-components';
import {Image} from 'antd';
import {Text} from '../../common/Format';
import {FlexContainer} from '../../common/Styled';
import {authSiteUrl, authLogoUrl, authLogoTitle} from '../../../services/const';
import {textToUpperCase} from '../../../services/helpers';
import {withTranslation, WithTranslation} from 'react-i18next';

export default withTranslation()(AuthHeader);

export interface IAuthHeaderProps extends WithTranslation {
  title: string;
}

const StyledTextFormat = styled(Text)`
  font-size: 1.3em;
  text-align: center;
`;

const StyledLink = styled.a`
  color: ${({theme}) => theme.colors.buttonActivePrimaryColor};

  &:hover {
    color: ${({theme}) => theme.colors.buttonActiveSecondaryColor};
  }
`;

const StyledImage = styled(Image)`
  margin-bottom: 25px;
  min-width: 150px;
  max-width: 300px;
  width: 20vw;
`;

const StyledContainer = styled(FlexContainer)`
  margin-bottom: 20px;
`;

function AuthHeader({title, t}: IAuthHeaderProps): JSX.Element {
  return (
    <StyledContainer
      justifyContent="center"
      alignItems="center"
      flexDirection="column">
      {authLogoUrl && (
        <StyledImage src={authLogoUrl} alt="logo" preview={false} />
      )}
      <StyledTextFormat primary>
        {textToUpperCase(t(title))}
        {authLogoTitle && authSiteUrl && (
          <StyledLink href={authSiteUrl}>{` "${textToUpperCase(
            authLogoTitle,
          )}"`}</StyledLink>
        )}
      </StyledTextFormat>
    </StyledContainer>
  );
}
