import React from 'react';
import {Form, Row, Col} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {DefaultForm, IDefaultFormProps, Button} from '../../../common/General';
import {Input} from '../../../common/DataEntry';
import {withTranslation, WithTranslation} from 'react-i18next';
import styled, {css} from 'styled-components';
import {LoginFormDTO} from '../../../../struture';

export default withTranslation()(LoginForm);

export interface ILoginFormProps
  extends WithTranslation,
    Omit<
      IDefaultFormProps<LoginFormDTO, LoginFormDTO>,
      'children' | 'initialValues' | 'additionalValuesRequest'
    > {
  onCancel?: () => void;
  onSuccess: (value: LoginFormDTO) => Promise<() => void>;
  onError?: () => Promise<void>;
  login: LoginFormDTO;
}

const iconStyle = css`
  color: ${({theme}) => theme.colors.disable};
`;

const StyledLockOutlined = styled(LockOutlined)`
  ${iconStyle}
`;
const StyledUserOutlined = styled(UserOutlined)`
  ${iconStyle}
`;

function LoginForm({login, ...rest}: ILoginFormProps): JSX.Element {
  const {t} = useTranslation();

  return (
    <DefaultForm initialValues={{...login}} showFooter={false} {...rest}>
      {({loadingSubmit, submit}) => (
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              // label={t('Email or Phone')}
              name="identifier"
              rules={[
                {
                  required: true,
                  message: t('Login must be specified'),
                },
              ]}>
              <Input
                data-testid="login-form__input--email"
                disabled={loadingSubmit}
                prefix={<StyledUserOutlined />}
                placeholder={t('Enter your email or phone')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              // label={t('Password')}
              name="password"
              rules={[
                {
                  required: true,
                  message: t('Password must be specified'),
                },
              ]}>
              <Input
                data-testid="login-form__input--password"
                disabled={loadingSubmit}
                prefix={<StyledLockOutlined />}
                type="password"
                placeholder={t('Enter your password')}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Button
              disabled={loadingSubmit}
              data-testid="login-form__button--submit"
              loading={loadingSubmit}
              type="primary"
              onClick={submit}
              block>
              {t('Login')}
            </Button>
          </Col>
        </Row>
      )}
    </DefaultForm>
  );
}
