import {Record} from 'immutable';

/**
 * @desc Статус для Категорії
 * */
export const CATEGORY_STATUS_ACTIVE = 1;

export interface CategoryProps {
  pos: string;
  status: number;
  uuid: string;
  title: string;
  description: string;
  categories: CategoryProps[];
  updated_at: string;
  created_at: string;
}

/**
 * @class Category
 * @classdesc
 * @extends {Record}
 * @property {string} id - backup id
 */
export default class Category extends Record({
  pos: '',
  status: null,
  uuid: '',
  title: '',
  description: '',
  categories: [],
  updated_at: null,
  created_at: null,
}) {
  /**
   * @constructor
   * @param {{}} props
   */
  constructor(props: CategoryProps = {} as CategoryProps) {
    super({...props});
  }
}
