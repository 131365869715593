import {applyMiddleware, createStore, Action} from 'redux';
import thunk, {ThunkAction} from 'redux-thunk';
import {StoreInitialState} from './types';
import rootReducer, {RootState} from './reducers';

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default function configureStore<T>(
  initState: StoreInitialState<RootState> = {},
) {
  return createStore(rootReducer, initState, applyMiddleware(thunk));
}
