import {GENDER} from '../../services/types';
import {Moment} from 'moment';
import {toMoment} from '../../services/helpers';

export interface IUserFormDTOProps {
  name: string;
  surname: string;
  middlename: string;
  id: string;
  phone: string;
  email: string;
  address: string;
  birthday: Moment | string;
  gender: GENDER;
}

export class UserFormDTO {
  public id: string;
  public name: string;
  public surname: string;
  public middlename: string;
  public phone: string;
  public email: string;
  public address: string;
  public birthday: Moment | string;
  public gender: GENDER;

  constructor(props: IUserFormDTOProps) {
    this.id = props?.id ?? '';
    this.name = props?.name ?? '';
    this.surname = props?.surname ?? '';
    this.middlename = props?.middlename ?? '';
    this.phone = props?.phone ?? '';
    this.email = props?.email ?? '';
    this.address = props?.address ?? '';
    this.birthday = props?.birthday ? toMoment(props?.birthday) : '';
    this.gender = props?.gender ?? GENDER.MALE;
  }
}
