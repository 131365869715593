import * as React from 'react';
import {CanceledPromiseError} from '../Errors';
import {isThereContent, eq} from '../../../services/helpers';

export interface IMakeCancelableReturnType {
  promise: Promise<any>;
  cancel: () => void;
}

export function makeCancelable<T>(
  promise: Promise<T>,
): IMakeCancelableReturnType {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then((val) =>
        isCanceled ? reject(new CanceledPromiseError()) : resolve(val),
      )
      .catch((error) =>
        isCanceled ? reject(new CanceledPromiseError()) : reject(error),
      );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true;
    },
  };
}

export interface IUseCancellablePromiseReturnType {
  cancellablePromise: <T>(p: Promise<any>) => Promise<T>;
  didCancel: React.MutableRefObject<boolean>;
}

/**
 * @info took and modified from https://github.com/rajeshnaroth/react-cancelable-promise-hook
 * @param cancelable
 * @returns {{cancellablePromise: (function(*=): Promise<any>)}}
 */
export default function useCancellablePromise<T>(
  cancelable: (
    promise: Promise<T>,
  ) => IMakeCancelableReturnType = makeCancelable,
): IUseCancellablePromiseReturnType {
  const emptyPromise = Promise.resolve(true as any);
  const didCancel = React.useRef<boolean>(false);

  if (eq(cancelable(emptyPromise).cancel, undefined)) {
    throw new Error(
      'promise wrapper argument must provide a cancel() function',
    );
  }

  const promises = React.useRef<IMakeCancelableReturnType[]>();

  React.useEffect(() => {
    promises.current = promises.current || [];
    didCancel.current = false;

    return () => {
      if (isThereContent(promises.current)) {
        promises.current.forEach((p) => p.cancel());
        promises.current = [];
      }
      didCancel.current = true;
    };
  }, []);

  const cancellablePromise: <U>(p: Promise<any>) => Promise<U> =
    React.useCallback(
      <U>(p: Promise<any>): Promise<U> => {
        const cPromise = cancelable(p);

        promises.current = promises.current
          ? [...promises.current, cPromise]
          : [cPromise];

        return cPromise.promise;
      },
      [cancelable],
    );

  return {cancellablePromise, didCancel};
}
