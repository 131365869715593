import React, {ErrorInfo} from 'react';
import {Modal, Result, Button} from 'antd';

export interface IErrorBoundaryProps {
  fallback: React.ReactNode;
  homeUrl: string;
  children: React.ReactNode | React.ReactNode[];
}

export interface IErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

export default class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will Show the fallback UI.
    return {error: error, hasError: true};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // this.setState({ errorInfo });
    // // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI

      return (
        <div>
          {this.props.fallback}
          <Modal title={null} visible={true} closable={false} footer={null}>
            <Result
              status="warning"
              title="Something went wrong."
              subTitle={this.state.error && this.state.error.message}
              extra={
                <span>
                  <Button
                    onClick={() =>
                      window.location.assign(this.props.homeUrl || '')
                    }>
                    Return Home
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => window.location.reload()}>
                    Reload Page
                  </Button>
                </span>
              }
            />
          </Modal>
        </div>
      );
    }

    return this.props.children;
  }
}
