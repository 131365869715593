import {Dispatch} from 'redux';
import {USER} from '../constants';
import {UserModel} from '../../struture';

export interface IStoreUser {
  type: USER;
  user: UserModel;
}

export interface IErrorUser {
  type: USER;
  error: Error | undefined;
}

export function storeUser(
  user: UserModel,
): (dispatch: Dispatch) => Promise<IStoreUser> {
  return async function (dispatch: Dispatch) {
    dispatch({
      type: USER.LOADING_USER,
    });

    return dispatch({
      type: USER.STORE_USER,
      user,
    });
  };
}

export function editStoreUser(
  user: UserModel,
): (dispatch: Dispatch) => Promise<IStoreUser> {
  return async function (dispatch: Dispatch) {
    dispatch({
      type: USER.LOADING_USER,
    });
    return dispatch({
      type: USER.UPDATE_USER,
      user,
    });
  };
}

export function errorStoreUser(
  error: any,
): (dispatch: Dispatch) => Promise<IErrorUser> {
  return async function (dispatch: Dispatch) {
    return dispatch({
      type: USER.ERROR_USER,
      error,
    });
  };
}
