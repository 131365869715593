import {UserDTO, UserFormDTO} from '../dto';
import {UserModel} from '../models';
import {PlanListMapper} from './PlanListMapper';
import {PLAN_STATUS} from '../../services/types';
import {toMoment, toMomentISOString} from '../../services/helpers';

export class UserMapper {
  public static toUserDTO(user: UserModel | UserFormDTO): UserDTO {
    return new UserDTO({
      name: user?.name,
      surname: user?.surname,
      middlename: user?.middlename,
      id: user?.id,
      status:
        user instanceof UserFormDTO ? PLAN_STATUS.NotActivated : user?.status,
      phone: user?.phone,
      email: user?.email,
      address: user?.address,
      currency_symbol: (user as UserModel)?.currency_symbol,
      birthday:
        user instanceof UserFormDTO
          ? toMomentISOString(user?.birthday)
          : user?.birthday,
      gender: user?.gender,
      lang: user instanceof UserFormDTO ? '' : user?.lang,
      status_message: user instanceof UserFormDTO ? '' : user?.status_message,
      last_login: user instanceof UserFormDTO ? '' : user?.last_login,
      terms_accepted:
        user instanceof UserFormDTO ? false : user?.terms_accepted,
      plans:
        user instanceof UserFormDTO
          ? []
          : PlanListMapper.toPlanListDTO(user.plans),
    });
  }

  public static toUserModel(userDTO: UserDTO): UserModel {
    return new UserModel(userDTO);
  }

  public static toUserFormDTO(userModel: UserModel): UserFormDTO {
    return new UserFormDTO({
      id: userModel?.id,
      name: userModel?.name,
      surname: userModel?.surname,
      middlename: userModel?.middlename,
      phone: userModel?.phone,
      email: userModel?.email,
      address: userModel?.address,
      gender: userModel?.gender,
      birthday: userModel?.birthday ? toMoment(userModel?.birthday) : '',
    });
  }
}
