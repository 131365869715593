import * as React from 'react';
import {IEntityProps, ApiError} from '../../../services/types';
import {PlanModel, PlanListDTO, PlanListMapper} from '../../../struture';
import {List} from 'immutable';
import useCancellablePromise from './useCancellablePromise';
import useStateReducer from './useStateReducer';
import {getSubscriptionList} from '../../../services/subscriptions';
import {len, isActivePlan} from '../../../services/helpers';

export interface IUsePlanListProps extends IEntityProps {}

export interface IUsePlanListReturnType {
  error: null | ApiError;
  loading: boolean;
  entityList: List<PlanModel> | null;
  refresh: (
    value: Partial<IUsePlanListProps>,
  ) => Promise<List<PlanModel> | void>;
  total: number;
}

export default function usePlanList({
  loadOnInit = true,
}: IUsePlanListProps = {}): IUsePlanListReturnType {
  const {cancellablePromise, didCancel} = useCancellablePromise();
  const {
    entityList,
    loading,
    error,
    total: listTotal,
    handleUpdate,
  } = useStateReducer<Omit<IUsePlanListReturnType, 'refresh'>>({
    entityList: null,
    total: 0,
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
    }: Partial<IUsePlanListProps> = {}): Promise<List<PlanModel> | void> => {
      try {
        handleUpdate({
          loading: showLoading,
          error: null,
        });

        const {plans} = await cancellablePromise<PlanListDTO>(
          getSubscriptionList(),
        );

        const planListModel = PlanListMapper.toPlanListModel(
          plans,
          len(plans) || 0,
        );

        isActivePlan(planListModel.plans);

        if (!didCancel.current) {
          handleUpdate({
            entityList: planListModel?.plans,
            total: planListModel?.total,
            loading: false,
          });

          return planListModel?.plans;
        }
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    [cancellablePromise, didCancel, error, handleUpdate],
  );

  React.useEffect(() => {
    if (loadOnInit) {
      (async () => {
        await refresh();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit]);

  return {
    error,
    loading,
    entityList,
    refresh,
    total: listTotal,
  };
}
