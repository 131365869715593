import React, {Suspense} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Spin, Button} from 'antd';
import {ButtonProps} from 'antd/lib/button';
import {useModal} from '../../common/hooks';
import {PlanTariffFormDTO} from '../../../struture';
import styled from 'styled-components';

const SubscriptionTariffSideWindowLazy = React.lazy(
  () => import('../Show/SubscriptionTariffSideWindow'),
);

export default withTranslation()(ChangeSubscriptionTariffButton);

export interface IChangeSubscriptionTariffButtonProps
  extends WithTranslation,
    ButtonProps {
  onSuccess: (value: PlanTariffFormDTO) => Promise<void>;
  onCancel?: () => void;
  subscription: PlanTariffFormDTO;
  title: string;
}

const StyledActiveButton = styled(Button)`
  width: 100%;
  background: ${({theme}) => theme.colors.success};
  border-color: ${({theme}) => theme.colors.success};

  &:hover,
  &:active,
  &:focus {
    background: ${({theme}) => theme.colors.successHover};
    border-color: ${({theme}) => theme.colors.successHover};
  }
`;

function ChangeSubscriptionTariffButton({
  t,
  loading = false,
  onSuccess,
  subscription,
  onCancel,
  title,
}: IChangeSubscriptionTariffButtonProps) {
  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <StyledActiveButton type="primary" onClick={handleOnInit}>
        {title}
      </StyledActiveButton>

      <Suspense fallback={<Spin />}>
        <SubscriptionTariffSideWindowLazy
          subscription={subscription}
          loading={loading as boolean}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          visible={visible}
        />
      </Suspense>
    </>
  );
}
