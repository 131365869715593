import React from 'react';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import Footer from './Footer';

withTranslation()(FooterWithoutLayout);

const StyledFooter = styled(Footer)`
  background-color: ${({theme}) => theme.colors.secondary};

  & .footer__text {
    color: ${({theme}) => theme.colors.app};
  }
`;

export default function FooterWithoutLayout(): JSX.Element {
  return <StyledFooter />;
}
