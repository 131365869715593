import React from 'react';
import {Empty, Spin} from 'antd';
import {withTranslation, WithTranslation} from 'react-i18next';
import styled from 'styled-components';
import {FlexContainer} from '../Styled';

export default withTranslation()(LoadingLayout);

export interface ILoadingLayoutProps extends WithTranslation {
  loading: boolean;
  children: React.ReactNode;
}

const EmptyContainer = styled(FlexContainer)`
  height: 80vh;
  background-color: ${({theme}) => theme.colors.background};
`;

function LoadingLayout({
  t,
  loading,
  children,
}: ILoadingLayoutProps): JSX.Element {
  return (
    <>
      {loading ? (
        <Spin>
          <EmptyContainer justifyContent="center" alignItems="center">
            <Empty description={`...${t('Loading')}`} />
          </EmptyContainer>
        </Spin>
      ) : (
        children
      )}
    </>
  );
}
