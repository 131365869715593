import React from 'react';
import {ProfileForm} from './Forms';
import {useStoredProfile} from '../common/hooks';
import {UserMapper} from '../../struture';
import {isThereContent} from '../../services/helpers';

export default function ProfilePage() {
  const {user, updateUser, loading} = useStoredProfile();

  return (
    <>
      {isThereContent(user) && (
        <ProfileForm
          profile={UserMapper.toUserFormDTO(user)}
          onSuccess={updateUser}
          loading={loading}
        />
      )}
    </>
  );
}
