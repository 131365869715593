import {PlanModel} from '../models';
import {toMoment} from '../../services/helpers';
import {Moment} from 'moment';

export class PlanTariffFormDTO {
  public uuid: string;
  public identifier: string;
  public package: string;
  public comment: string;
  public amount: number;
  public start_date: Moment;

  constructor(props?: PlanModel) {
    this.uuid = props?.id || '';
    this.identifier = props?.identifier || '';
    this.package = '';
    this.comment = '';
    this.amount = 3;
    this.start_date = toMoment(new Date());
  }
}
