import {PlanDTO, PlanFormDTO, PlanTariffFormDTO} from '../dto';
import {PlanListModel, PlanModel} from '../models';
import {List} from 'immutable';
import {listToArray} from '../../services/helpers';

export class PlanListMapper {
  public static toPlanListModel(
    planListDTO: PlanDTO[],
    total: number,
  ): PlanListModel {
    return new PlanListModel({plans: planListDTO, total});
  }

  public static toPlanListDTO(planListModel: List<PlanModel>): PlanDTO[] {
    const planList = listToArray<PlanModel>(planListModel);

    if (Array.isArray(planList)) {
      return planList.map((plan) => new PlanDTO(plan as any));
    }
    return [];
  }

  public static toPlanFormDTO(planModel: PlanModel): PlanFormDTO {
    return new PlanFormDTO(planModel);
  }

  public static toPlanTariffFormDTO(planModel: PlanModel): PlanTariffFormDTO {
    return new PlanTariffFormDTO(planModel);
  }
}
