import * as React from 'react';
import usePlanList, {
  IUsePlanListProps,
  IUsePlanListReturnType,
} from './usePlanList';
import {List} from 'immutable';
import {PlanModel} from '../../../struture';
import useStateEntityList from './useStateEntityList';

export interface IUseStatePlanListProps extends IUsePlanListProps {}

export interface IUseStatePlanListReturnType
  extends Omit<IUsePlanListReturnType, 'entityList'> {
  plans: List<PlanModel> | null;
}

export default function useStatePlanList({
  ...rest
}: IUseStatePlanListProps = {}): IUseStatePlanListReturnType {
  const {
    entityList,
    refresh,
    loading: plansLoading,
    total,
    ...plansParams
  } = usePlanList({
    ...rest,
  });

  const {entityList: plans, loading} = useStateEntityList<PlanModel>({
    entityList,
    refresh,
  });

  return {
    plans,
    refresh,
    total,
    loading: plansLoading || loading,
    ...plansParams,
  };
}
