import {Record, List} from 'immutable';
import {ListModel, ListProps} from './List';
import Category, {CategoryProps} from './Category';

export interface CategoryListProps extends ListProps {
  categories: CategoryProps[];
}

export interface CategoryListReturnType extends ListProps {
  categories: List<Category>;
}

/**
 * @class CategoryList
 * @classdesc Category list model.
 * @extends {Record}
 * @property {List} categories - list of categories
 * @property {number} total - total amount of records on backend
 */
export default class CategoryList extends Record<CategoryListReturnType>({
  categories: List(),
  ...ListModel.toJS(),
} as any) {
  /**
   * @constructor
   * @param {{categories:[]}} param0
   */
  constructor({categories = [], ...props}: CategoryListProps) {
    super({
      categories: List(categories.map((category) => new Category(category))),
      ...props,
    });
  }
}
