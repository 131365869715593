import React from 'react';
import {ThemeProvider} from 'styled-components';
import Routes from './routes/Routes';
import {theme} from '../style/themes/styled';
import {useAppInfo} from '../components/common/hooks';

import '../style/App.less';

export default App;

function App() {
  useAppInfo();
  return (
    <div className="App" data-testid="app">
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </div>
  );
}
