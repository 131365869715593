import React from 'react';
import {WithTranslation} from 'react-i18next';
import styled from 'styled-components';
import Status, {IStatusProps} from './Status';
import {FlexContainer} from '../Styled';
import {Text} from '../Format';

export interface IStatusTitleProps
  extends Omit<IStatusProps, keyof WithTranslation> {
  children: React.ReactNode;
}

const StyledFlexContainer = styled(FlexContainer)`
  height: fit-content;
  width: auto;
`;

const HeaderTitle = styled(Text)`
  font-size: 2em;
  font-weight: bold;

  @media (max-width: 475px) {
    font-size: 1em;
  }
`;

export default function StatusTitle({
  children,
  ...rest
}: IStatusTitleProps): JSX.Element {
  return (
    <StyledFlexContainer flexDirection="column">
      <HeaderTitle>{children}</HeaderTitle>
      <Status {...rest} />
    </StyledFlexContainer>
  );
}
