import {apiGet, apiPost} from './core';
import {authUrl} from './const';
import {
  PlanListDTO,
  IPlanDTOProps,
  PlanModel,
  PlanListMapper,
  PlanFormDTO,
  PlanTariffFormDTO,
} from '../struture';
import {ApiAnswer} from './types';
import {toDate} from '../services/helpers';

export interface IPlans {
  id: string;
  title: string;
  price: number;
  duration: number;
  period: string;
  defaultStatus: string;
}

export interface ISubscriptionPlanRecalculationProps {
  start_date: Date;
  packageName: string;
  amount: number;
  uuid: string;
}

export interface IGetSubscriptionListReturnType extends ApiAnswer {
  plans: IPlanDTOProps[];
}

export interface ISubscriptionActivateAnswer extends ApiAnswer {
  checkout_url: string;
}

export const SUBSCRIPTION_FORM_PARAM = PlanListMapper.toPlanFormDTO(
  new PlanModel(),
);

export interface ISubscriptionPeriods {
  uuid: number;
  title: string;
}

export const SUBSCRIPTION_PERIODS: ISubscriptionPeriods[] = [
  {
    uuid: 3,
    title: '3 month',
  },
  {
    uuid: 6,
    title: 'Half year',
  },
  {
    uuid: 12,
    title: 'Year',
  },
];

export const SUBSCRIPTION_TARIFF_FORM_PARAM =
  PlanListMapper.toPlanTariffFormDTO(new PlanModel());

export async function getSubscriptionList(): Promise<any> {
  const {plans} = await apiGet<null, IGetSubscriptionListReturnType>(
    `${authUrl}/users/subscriptions`,
  );

  return new PlanListDTO({plans});
}

export async function subscriptionActivate({
  periods,
  uuid,
  comment,
  discount,
  amount,
}: PlanFormDTO): Promise<string> {
  const {checkout_url} = await apiPost<any, ISubscriptionActivateAnswer>(
    `${authUrl}/subscriptions/${uuid}/checkout-url`,
    {periods, comment, discount, amount},
  );

  return checkout_url;
}

export async function subscriptionPlanRecalculation({
  start_date,
  amount,
  packageName,
  uuid,
}: ISubscriptionPlanRecalculationProps): Promise<number> {
  const {total} = await apiPost<any, ApiAnswer & {total: number}>(
    `${authUrl}/subscriptions/${uuid}/transition-recalculation`,
    {start_date: toDate(start_date), amount, package: packageName},
  );

  return total;
}

export async function subscriptionTransition({
  start_date,
  amount,
  package: packageName,
  uuid,
  comment,
}: Omit<PlanTariffFormDTO, 'identifier'>): Promise<string> {
  const {checkout_url} = await apiPost<
    Omit<PlanTariffFormDTO, 'identifier' | 'uuid'>,
    ISubscriptionActivateAnswer
  >(`${authUrl}/subscriptions/${uuid}/transition`, {
    start_date: toDate(start_date as any) as any,
    amount,
    package: packageName,
    comment,
  });

  return checkout_url;
}
