import {GENDER, PLAN_STATUS, VALUES_PLAN_STATUS} from '../../services/types';
import moment from 'moment';
import {PlanDTO} from './PlanDTO';
import {PlanListDTO} from './PlanListDTO';

export interface IUserDTOProps {
  id: string;
  status: VALUES_PLAN_STATUS;
  phone: string;
  name: string;
  surname: string;
  address: string;
  middlename: string;
  birthday: string;
  email: string;
  lang: string;
  status_message: string;
  last_login: string;

  currency_symbol: string;
  terms_accepted: boolean;
  plans: PlanDTO[];
  gender: GENDER;
}

export class UserDTO {
  public id: string;
  public status: VALUES_PLAN_STATUS;
  public phone: string;
  public name: string;
  public surname: string;
  public address: string;
  public middlename: string;
  public email: string;
  public lang: string;
  public status_message: string;
  public last_login: string;
  public birthday: string;
  public currency_symbol: string;
  public terms_accepted: boolean;
  public plans: PlanDTO[];
  public gender: GENDER;

  constructor(props: IUserDTOProps) {
    this.id = props?.id ?? '';
    this.status = props?.status ?? PLAN_STATUS.NotActivated;
    this.phone = props?.phone ?? '';
    this.name = props?.name ?? '';
    this.surname = props?.surname ?? '';
    this.address = props?.address ?? '';
    this.middlename = props?.middlename ?? '';
    this.email = props?.email ?? '';
    this.birthday = props?.birthday ?? '';
    this.lang = props?.lang || 'ua';
    this.currency_symbol = props?.currency_symbol || '₴';
    this.status_message = props?.status_message ?? '';
    this.last_login = props?.last_login ?? '';
    this.terms_accepted = props?.terms_accepted ?? false;
    this.plans = new PlanListDTO({plans: props?.plans ?? []}).plans;
    this.gender = props?.gender ?? GENDER.MALE;
  }

  birthdayToISO(birthday: any): string {
    return moment(birthday).toISOString();
  }
}
