import React from 'react';
import styled from 'styled-components';
import {FlexContainer} from '../Styled';

export interface ILogoProps {
  url: string;
}

const Container = styled(FlexContainer)`
  width: 100%;
  height: 100%;
`;

const StyleLogo = styled.img`
  width: 80%;
  height: 60%;
`;

export default function Logo({url}: ILogoProps): JSX.Element {
  return (
    <Container justifyContent="center" alignItems="center">
      <StyleLogo src={url} alt="logo" />
    </Container>
  );
}
