import React, {useCallback} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {restore} from '../../../services/profile';
import {ForgotPasswordForm} from './Forms';
import {
  ForgotPasswordDTO,
  ForgotPasswordFormDTO,
  ForgotPasswordMapper,
} from '../../../struture';
import AuthLayout from '../Show/AuthLayout';
import {ApiError} from '../../../services/types';
import {useNotify} from '../../common/hooks';

export default withTranslation()(ForgotPasswordPage);

export interface IForgotPasswordPageProps extends WithTranslation {}

/**
 * @name ForgotPasswordPage
 * @desc ForgotPasswordPage component.
 */
function ForgotPasswordPage({t}: IForgotPasswordPageProps) {
  const {notifyMessage, notifyErrorMessage} = useNotify();

  /**
   * @name handleSubmit
   * @desc Handles form submit.
   * @private
   */
  const handleSubmit = useCallback(
    async (value: ForgotPasswordFormDTO): Promise<any> => {
      const forgotPasswordDTO = ForgotPasswordMapper.toForgotPasswordDTO(value);
      await restore(forgotPasswordDTO);

      notifyMessage(
        `${t('An email has been sent to')} ${forgotPasswordDTO?.identifier} ${t(
          'with the instructions to reset your password.',
        )}`,
      );
    },
    [t, notifyMessage],
  );

  const notifyError = useCallback(
    (error: ApiError) => {
      notifyErrorMessage(
        'An error occurred while resetting your password',
        error?.message,
      );
    },
    [notifyErrorMessage],
  );

  return (
    <AuthLayout title={'Manage the subscriptions'}>
      <ForgotPasswordForm
        notifyError={notifyError}
        forgotPassword={ForgotPasswordMapper.toForgotPasswordFormDTO(
          new ForgotPasswordDTO(),
        )}
        onSuccess={handleSubmit}
      />
    </AuthLayout>
  );
}
