import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Button} from 'antd';
import {GreetingMessage} from './Show';
import {Warning} from '../common/DataDisplay';
import {Text} from '../common/Format';
import styled from 'styled-components';
import {useStoredProfile} from '../common/hooks';

export default withTranslation()(DashboardPage);

export interface IDashboardPageProps extends WithTranslation {}

const StyledButton = styled(Button)`
  align-self: center;
  width: 50%;
  background: ${({theme}) => theme.colors.white};
  border-color: ${({theme}) => theme.colors.white};

  &:hover,
  &:active,
  &:focus {
    background: ${({theme}) => theme.colors.whiteHover};
    border-color: ${({theme}) => theme.colors.whiteHover};
  }
`;

const StyledText = styled(Text)`
  color: ${({theme}) => theme.colors.warning};
  text-shadow: none;
`;

function DashboardPage({t}: IDashboardPageProps): JSX.Element {
  const {user, handleGenerateEmailCode} = useStoredProfile();

  return (
    <>
      {user?.status === -2 ? (
        <Warning
          title={`${t(
            'You have not activated your current account, for this you need to confirm your mail. Please check your mailbox where the activation letter was sent immediately after registration. If the activation link has expired, generate a new request',
          )}.`}>
          <StyledButton type="primary" onClick={handleGenerateEmailCode}>
            <StyledText>{t('Generate')}</StyledText>
          </StyledButton>
        </Warning>
      ) : null}
      <GreetingMessage />
    </>
  );
}
