import {firstLetterToUppercase, compose, divide} from './index';

export const getFirstLatter = (value: string): string => value.charAt(0);

export const getFirstLatterToUpperCase = compose<string>(
  getFirstLatter,
  firstLetterToUppercase,
);

export const getStringPattern = (value: string): [string, string] => {
  const replaceSpaces = value.replaceAll(' ', '');
  const middleNumberOfString = Math.floor(
    divide<[number, number], number>(replaceSpaces.length, 2),
  );
  const firstPatter = `${replaceSpaces.slice(0, middleNumberOfString)} `;
  const secondPatter = ` ${replaceSpaces.slice(-middleNumberOfString)}`;

  return [firstPatter, secondPatter];
};
