import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import {translationEN, translationUA} from './services/helpers';
import {domainUrl, LocalStorageItems} from './services/const';
import {setDateLanguage} from './services/helpers';

const resources = {
  en: {
    translation: translationEN,
  },
  ua: {
    translation: translationUA,
  },
};

i18n.on('initialized', () => {
  const lang = i18n.language;

  setDateLanguage(
    window.localStorage.getItem(LocalStorageItems.language) || lang,
  );
});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: {
      order: [
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
      ],

      lookupQuerystring: 'lng',
      lookupCookie: 'language',
      lookupLocalStorage: 'language',
      lookupSessionStorage: 'language',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      caches: ['localStorage', 'cookie'],

      cookieDomain: domainUrl,

      htmlTag: document.documentElement,

      cookieOptions: {path: '/', sameSite: 'strict'},
    },

    fallbackLng: 'en',

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', setDateLanguage);

export default i18n;
