import * as React from 'react';
import {getCookie} from '../../../services/helpers';
import {TokenHandler} from '../../../services/core';
import {useHistory} from 'react-router-dom';

export interface IUsePreLoadCookieReturnType {
  access_token: string | undefined | null;
  is_verify_email: string | undefined | null;
}

export default function usePreLoadCookie(): IUsePreLoadCookieReturnType {
  const history = useHistory();

  const access_token_cookie = getCookie('access_token');
  const refresh_token_cookie = getCookie('refresh_token');
  const is_verify_email_cookie = getCookie('is_verify_email');

  const restoreFromCookiesStatus = TokenHandler.getRestoreFromCookiesStatus();

  React.useEffect(() => {
    if (
      access_token_cookie &&
      refresh_token_cookie &&
      is_verify_email_cookie &&
      restoreFromCookiesStatus !== '-1'
    ) {
      (async () => {
        TokenHandler.setAuthTokens({
          refresh: refresh_token_cookie,
          access: access_token_cookie,
          is_verify_email: is_verify_email_cookie || '',
        });
      })();
    }
  }, [
    restoreFromCookiesStatus,
    access_token_cookie,
    history,
    is_verify_email_cookie,
    refresh_token_cookie,
  ]);

  return {
    access_token:
      restoreFromCookiesStatus !== '-1' ? access_token_cookie : null,
    is_verify_email:
      restoreFromCookiesStatus !== '-1' ? is_verify_email_cookie : null,
  };
}
