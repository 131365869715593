import {Record, List} from 'immutable';
import {UserDTO} from '../dto';
import {
  firstLetterToUppercase,
  getFirstLatterToUpperCase,
  toMoment,
} from '../../services/helpers';
import {GENDER, PLAN_STATUS, VALUES_PLAN_STATUS} from '../../services/types';
import {Moment} from 'moment';
import {PlanModel} from './PlanModel';
import {PlanListModel} from './PlanListModel';

export interface IUserModelReturnType {
  id: string;
  status: VALUES_PLAN_STATUS;
  phone: string;
  name: string;
  surname: string;
  address: string;
  middlename: string;
  birthday: string;
  email: string;
  lang: string;
  status_message: string;
  last_login: string;

  currency_symbol: string;
  terms_accepted: boolean;
  plans: List<PlanModel>;
  gender: GENDER;
}

export class UserModel extends Record<IUserModelReturnType>({
  id: '',
  status: PLAN_STATUS.NotActivated,
  phone: '',
  name: '',
  surname: '',
  address: '',
  middlename: '',
  birthday: '',
  email: '',
  lang: '',
  status_message: '',
  last_login: '',
  currency_symbol: '₴',
  terms_accepted: false,
  plans: List(),
  gender: GENDER.MALE,
}) {
  constructor(props = {} as UserDTO) {
    const options: IUserModelReturnType = {
      id: props?.id ?? '',
      status: props?.status ?? PLAN_STATUS.NotActivated,
      phone: props?.phone ?? '',
      name: props?.name ?? '',
      surname: props?.surname ?? '',
      address: props?.address ?? '',
      birthday: props?.birthday ?? '',
      middlename: props?.middlename ?? '',
      email: props?.email ?? '',
      lang: props?.lang ?? 'ua',
      status_message: props?.status_message ?? '',
      last_login: props?.last_login ?? '',
      currency_symbol: props?.currency_symbol ?? '',
      terms_accepted: props?.terms_accepted ?? false,
      plans: new PlanListModel({
        plans: props?.plans ?? [],
        total: props?.plans?.length,
      }).plans,
      gender: props?.gender ?? GENDER.MALE,
    };
    super(options);
  }

  get fullName(): string {
    return `${firstLetterToUppercase(this.surname)} ${firstLetterToUppercase(
      this.name,
    )} `;
  }

  get shortFullName(): string {
    return `${firstLetterToUppercase(this.surname)} ${getFirstLatterToUpperCase(
      this.name,
    )}. ${
      this.middlename ? `${getFirstLatterToUpperCase(this.middlename)}.` : ''
    }`;
  }

  get isFullName(): boolean {
    return !!this.name && !!this.surname;
  }

  get birthdayToMoment(): Moment | string {
    return this.birthday ? toMoment(this.birthday) : '';
  }
}
