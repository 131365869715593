import React from 'react';
import {Modal, ModalProps} from 'antd';
import {withTranslation, WithTranslation} from 'react-i18next';

export default withTranslation()(CenterModal);

export interface ICenterModalProps extends ModalProps, WithTranslation {
  children: React.ReactNode;
}

function CenterModal({children, t, ...rest}: ICenterModalProps): JSX.Element {
  return (
    <Modal
      cancelText={t('Cancel')}
      okText={t('Ok')}
      width={'75vw'}
      bodyStyle={{height: '75vh'}}
      centered
      {...rest}>
      {children}
    </Modal>
  );
}
