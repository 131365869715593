import styled, {css} from 'styled-components';
import {
  ContactsOutlined,
  ContainerOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  FileDoneOutlined,
  FundProjectionScreenOutlined,
  HomeOutlined,
  IdcardOutlined,
  OrderedListOutlined,
  PictureOutlined,
  SettingOutlined,
  UserOutlined,
  GroupOutlined,
  FileOutlined,
  GoogleOutlined,
} from '@ant-design/icons';

export const iconStyle = css`
  padding: 5px;
  border-radius: 5px;
  color: ${({theme}) => theme.colors.app};
  line-height: 0 !important;
`;

export const StyledOrderedListOutlined = styled(OrderedListOutlined)`
  ${iconStyle};
  background-color: ${({theme}) => theme.colors.darkOrchid};
`;

export const StyledUserOutlined = styled(UserOutlined)`
  ${iconStyle};
  background-color: ${({theme}) => theme.colors.yellowGold};
`;
