import moment from 'moment';
import {LocalStorageItems} from '../const';

export const setDateLanguage = (lng: string): void => {
  if (lng === 'ua') {
    moment.locale('uk');
    return;
  }

  localStorage.setItem(LocalStorageItems.language, lng);

  moment.locale(lng);
};
