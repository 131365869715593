import React from 'react';
import styled, {css} from 'styled-components';

export interface ITextProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > {
  children: React.ReactNode;
  breakWord?: boolean;
  bold?: boolean;
  muted?: boolean;
  info?: boolean;
  success?: boolean;
  warning?: boolean;
  error?: boolean;
  danger?: boolean;
  primary?: boolean;
  invert?: boolean;
  className?: string;
}

export const StyledText = styled.span<any>`
  ${({breakWord}) =>
    breakWord &&
    css`
      word-break: break-word;
    `}
  ${({bold}) =>
    bold &&
    css`
      font-weight: 700;
    `}
  ${({muted}) =>
    muted &&
    css`
      color: ${({theme}) => theme.colors.textLighten};
    `}
  ${({info}) =>
    info &&
    css`
      color: ${({theme}) => theme.colors.info};
    `}
  ${({success}) =>
    success &&
    css`
      color: ${({theme}) => theme.colors.success};
    `}
  ${({warning}) =>
    warning &&
    css`
      color: ${({theme}) => theme.colors.warning};
    `}
  ${({danger, error}) =>
    (danger || error) &&
    css`
      color: ${({theme}) => theme.colors.error};
    `}
  ${({primary}) =>
    primary &&
    css`
      color: ${({theme}) => theme.colors.primary};
    `};

  ${({invert}) =>
    invert &&
    css`
      color: ${({theme}) => theme.colors.app};
    `};
`;

export default function Text({
  children,
  breakWord,
  bold,
  muted,
  info,
  success,
  warning,
  error,
  danger,
  primary,
  invert,
  className,
  ...rest
}: ITextProps): JSX.Element {
  return (
    <StyledText
      className={className}
      breakWord={breakWord}
      bold={bold}
      muted={muted}
      info={info}
      success={success}
      warning={warning}
      error={error}
      danger={danger}
      primary={primary}
      invert={invert}
      {...rest}>
      {children}
    </StyledText>
  );
}
