import React from 'react';
import {ButtonProps} from 'antd/lib/button';
import {ActionButton} from '../../common/General';
import {isFunction} from '../../../services/helpers';

export interface IEmployeeCreateButton extends ButtonProps {
  loading: boolean;
  onSuccess?: () => void;
  tooltip?: string;
}

export default function ProfileFormButton({
  loading = false,
  onSuccess,
  children,
  tooltip,
  ...rest
}: IEmployeeCreateButton) {
  const handleClick = (): void => {
    if (isFunction(onSuccess)) {
      onSuccess();
    }
  };

  return (
    <ActionButton
      tooltip={tooltip || ''}
      type="primary"
      loading={loading}
      onClick={handleClick}
      data-testid="profile__button--form"
      {...rest}>
      {children}
    </ActionButton>
  );
}
