import React from 'react';
import {FlexContainer} from '../Styled';
import styled from 'styled-components';

const StyledHeader = styled(FlexContainer)`
  height: 50px;
  background-color: ${({theme}) => theme.colors.buttonPrimaryColor};
`;

export default function HeaderWithoutLayout() {
  return <StyledHeader />;
}
