import React, {Suspense} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Spin} from 'antd';
import {ButtonProps} from 'antd/lib/button';
import {Button} from '../../common/General';
import {useModal} from '../../common/hooks';
import {PlanFormDTO} from '../../../struture';
import styled from 'styled-components';

const SubscriptionSideWindowLazy = React.lazy(
  () => import('../Show/SubscriptionSideWindow'),
);

export default withTranslation()(SubscriptionButton);

export interface ISubscriptionButtonProps extends WithTranslation, ButtonProps {
  onSuccess: (value: PlanFormDTO) => Promise<void>;
  onCancel?: () => void;
  subscription: PlanFormDTO;
  title: string;
}

const StyledActiveButton = styled(Button)`
  width: 100%;
  margin-top: 15px;
`;

function SubscriptionButton({
  t,
  loading = false,
  onSuccess,
  subscription,
  onCancel,
  title,
  ...rest
}: ISubscriptionButtonProps) {
  const {handleCancel, handleOnInit, handleSuccess, visible} = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <StyledActiveButton type="primary" onClick={handleOnInit}>
        {title}
      </StyledActiveButton>

      <Suspense fallback={<Spin />}>
        <SubscriptionSideWindowLazy
          subscription={subscription}
          loading={loading as boolean}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
          visible={visible}
        />
      </Suspense>
    </>
  );
}
