import React, {useCallback, Dispatch, SetStateAction} from 'react';
import {Form} from 'antd';
import {FormProps} from 'antd/lib/form';
import {FormInstance} from 'antd/lib/form/Form';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import FormFooter, {IFormFooterProps} from './FormFooter';
import {isFunction} from '../../../services/helpers';
import {ApiError, KeyOfObject} from '../../../services/types';
import {useDefaultForm} from '../Context';
import {useDidCancel} from '../hooks';
import {WithTranslation} from 'react-i18next';

export interface IDefaultFormReturnedProps extends FormInstance {
  loadingSubmit: boolean;
  handlerUpdateFormState: (value: any) => void;
  formData: any;
}

export type FuncChildren = (data: IDefaultFormReturnedProps) => React.ReactNode;

type OnSuccessReturnType = () => void;

export interface IDefaultLocalFormProps<T, U>
  extends Omit<IFormFooterProps, keyof WithTranslation> {
  initialValues: T;
  additionalValuesRequest?: any;
  children: FuncChildren | React.ReactNode;
  form: FormInstance;
  setLoadingSubmit: Dispatch<SetStateAction<boolean>>;
  editMode?: boolean;
  onCancel?: () => void;
  onSuccess?: (value: U) => Promise<void | OnSuccessReturnType>;
  onValuesChange?: (value: KeyOfObject<T>) => void;
  showFooter?: boolean;
  className?: string;
  withContext?: boolean;
  id?: string;
  notifySuccess?: (editMode?: boolean) => void;
  notifyError?: (error: ApiError, editMode?: boolean) => void;
}

const StyledForm = styled(Form)<FormProps & any>`
  width: 100%;
`;

export default function DefaultForm<T, U>({
  editMode,
  initialValues = {} as T,
  onCancel,
  onSuccess,
  onValuesChange,
  children,
  showFooter = true,
  className,
  additionalValuesRequest = {},
  form,
  setLoadingSubmit,
  withContext,
  submitButtonText,
  id,
  notifySuccess,
  notifyError,
  ...rest
}: IDefaultLocalFormProps<T, U>): JSX.Element {
  const {t} = useTranslation();
  const {formData, loadingSubmit, handlerUpdateFormState} = useDefaultForm();
  const didCancel = useDidCancel();

  const handleSubmit = useCallback(
    async (value: T) => {
      setLoadingSubmit(true);
      try {
        const data: U = {
          ...value,
          ...additionalValuesRequest,
        };

        const correctFormData = (({errorFields, ...rest}) => ({...rest}))(
          formData,
        );

        const contextData: U = {
          ...correctFormData,
          ...additionalValuesRequest,
        };

        if (isFunction(onSuccess)) {
          const afterSuccess = await onSuccess(
            withContext ? contextData : data,
          );

          if (!didCancel.current) {
            setLoadingSubmit(false);
          }

          if (isFunction(notifySuccess)) {
            notifySuccess(editMode);
          }

          if (isFunction(afterSuccess)) {
            afterSuccess();
          }
        }
      } catch (err: any) {
        if (isFunction(notifyError)) {
          notifyError(err, editMode);
        }

        if (!didCancel.current) {
          setLoadingSubmit(false);
        }
      }
    },
    [
      setLoadingSubmit,
      additionalValuesRequest,
      formData,
      onSuccess,
      withContext,
      didCancel,
      notifySuccess,
      editMode,
      notifyError,
    ],
  );

  return (
    <StyledForm
      id={id}
      form={form}
      layout="vertical"
      className={className}
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
      initialValues={initialValues}>
      {isFunction(children)
        ? children({...form, loadingSubmit, handlerUpdateFormState, formData})
        : children}
      {showFooter && (
        <FormFooter
          {...rest}
          submitButtonText={submitButtonText || t('Save')}
          cancelButtonText={t('Cancel')}
          submitButtonProps={{
            'data-testid': 'form-submit',
            loading: loadingSubmit,
          }}
          onCancel={() => {
            if (isFunction(onCancel)) {
              onCancel();
            }
          }}
        />
      )}
    </StyledForm>
  );
}
