import React from 'react';
import {Spin} from 'antd';
import {AppErrorBoundary} from '../Errors';
import AuthLayout from './AuthLayout';
import {withTranslation, WithTranslation} from 'react-i18next';

export default withTranslation()(AuthRouteLayout);

export interface IAuthRouteLayoutProps extends WithTranslation {
  children: any;
}

function AuthRouteLayout({children, t}: IAuthRouteLayoutProps) {
  return (
    <Spin size="large" tip={t('Please wait...')} spinning={false} delay={500}>
      <AppErrorBoundary>
        <AuthLayout>{children}</AuthLayout>
      </AppErrorBoundary>
    </Spin>
  );
}
