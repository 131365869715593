import React from 'react';
import styled from 'styled-components';
import {FlexContainer} from '../Styled';
import {withTranslation, WithTranslation} from 'react-i18next';
import {Text} from '../Format';
import {footerAppName} from '../../../services/const';
// @ts-ignore
import packagejson from '../../../../package.json';

export default withTranslation()(FooterWithoutLayout);

export interface IFooterWithoutLayoutProps extends WithTranslation {
  className?: string;
}

const StyledFooter = styled(FlexContainer)`
  background-color: ${({theme}) => theme.colors.background};
  height: 50px;
  padding: 0 15px;
`;

const StyledText = styled(Text)`
  color: ${({theme}) => theme.colors.primary};

  @media (max-width: 475px) {
    text-align: center;
  }
`;

function FooterWithoutLayout({
  t,
  className,
}: IFooterWithoutLayoutProps): JSX.Element {
  const year: number = new Date().getFullYear();
  return (
    <StyledFooter
      justifyContent="center"
      alignItems="center"
      className={className}>
      <StyledText className="footer__text">
        © {year} {footerAppName} v{packagejson?.version} &nbsp;
        {t('All Rights Reserved.')}
      </StyledText>
    </StyledFooter>
  );
}
