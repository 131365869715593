import {
  compose,
  joinArr,
  slice,
  split,
  curry,
  neq,
  eq,
  find,
  head,
  last,
  len,
  not,
  some,
  setCookie,
} from './index';
import {ILiteralObj, PLAN_STATUS, Routes} from '../types';
import {List} from 'immutable';
import {PlanModel} from '../../struture';
import {domainUrl} from '../const';

export const getRootKey = compose<string>(joinArr(''), slice(1, 2), split('/'));

export const isNotEqualById = curry<[string, string], boolean>(
  (id: string, targetId: string): boolean => neq(id, targetId),
);

export const updateByIdentifier = curry<[any, any, any], any>(
  <T extends ILiteralObj>(identifier: string, updated: T, target: T): T => {
    if (eq(target[identifier], updated[identifier])) {
      return updated;
    }
    return target;
  },
);

export const filterFormErrorFields = curry<any, any>(
  <T extends ILiteralObj>(
    formFields: [string, string][],
    errorField: T,
  ): boolean => {
    const field = find(
      ([key]: [string, string]): boolean => eq(key, head(errorField?.name)),
      formFields,
    );

    return Array.isArray(field) ? !(eq(len(field), 2) && last(field)) : true;
  },
);

const isIncludesString = curry<[string[], string], boolean>(
  (array: string[], item: string): boolean => array.includes(item),
);

export const checkIsAdminRoute = (
  path: string,
  adminRoutes: Routes[] = [],
): boolean =>
  compose<boolean>(
    not,
    some(isIncludesString(adminRoutes) as any),
    split('/'),
  )(path);

export const isActivePlan = (
  plans: List<PlanModel> | undefined,
): boolean | undefined => {
  if (List.isList(plans)) {
    const isInactive = plans.some(
      (plan) => plan?.status !== PLAN_STATUS.Active,
    );

    if (isInactive) {
      setCookie('is_active_plan', 'false', {
        domain: domainUrl,
      });
    } else {
      setCookie('is_active_plan', 'true', {
        domain: domainUrl,
      });
    }

    return !isInactive;
  }
};
