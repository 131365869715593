import styled, {css} from 'styled-components';
import {Button} from 'antd';

export const headerButtonTheme = css`
  background-color: ${({theme}) => theme.colors.app};
  text-shadow: none;

  & * {
    color: ${({theme}) => theme.colors.secondary};
  }

  &:hover,
  &:focus {
    background-color: ${({theme}) => theme.colors.appHover};
  }
`;
