import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {IUserInitialState} from '../../../store/reducers/user';
import {
  editStoreUser as editStoreUserAction,
  errorStoreUser as errorStoreUserAction,
  storeUser as storeUserAction,
} from '../../../store/actions';
import {editProfile, generateEmailCode} from '../../../services/profile';
import {UserMapper, UserModel, UserFormDTO} from '../../../struture';
import useUser from './useUser';
import {isThereContent} from '../../../services/helpers';
import {REDUX_STATUS, Routes} from '../../../services/types';

export interface IUseStoredUserReturnType extends IUserInitialState {
  storeUser: (user: UserModel) => Promise<void>;
  updateUser: (value: any) => Promise<void>;
  handleGenerateEmailCode: (value: any) => Promise<void>;
  adminRoutes: Routes[];
}

export default function useStoredUser(): IUseStoredUserReturnType {
  const {status, ...storedUser} = useSelector(({user}: RootState) => user);

  const {refresh: userRefresh} = useUser({loadingOnInit: false});

  const dispatch = useDispatch();

  const storeUser = React.useCallback(
    async (user: UserModel): Promise<void> => {
      dispatch(storeUserAction(user));
    },
    [dispatch],
  );

  const updateUser = React.useCallback(
    async (value: UserFormDTO): Promise<void> => {
      try {
        const userDTO = UserMapper.toUserDTO(
          UserMapper.toUserFormDTO(value as any),
        );

        const user = await editProfile(userDTO);

        const userModel = UserMapper.toUserModel(user);

        dispatch(editStoreUserAction(userModel));
      } catch (e: any) {
        dispatch(errorStoreUserAction(e));
        throw new Error(e.message);
      }
    },
    [dispatch],
  );

  const handleGenerateEmailCode = React.useCallback(async (): Promise<void> => {
    try {
      const user = await generateEmailCode();

      const userModel = UserMapper.toUserModel(user);

      dispatch(editStoreUserAction(userModel));
    } catch (e: any) {
      dispatch(errorStoreUserAction(e));
      throw new Error(e.message);
    }
  }, [dispatch]);

  const adminRoutes = React.useMemo(() => [], []);

  React.useEffect(() => {
    if (status === REDUX_STATUS.IDLE) {
      (async () => {
        const user = await userRefresh();

        if (isThereContent(user)) {
          await storeUser(user);
        }
      })();
    }
  }, [userRefresh, status, storeUser]);

  return {
    ...storedUser,
    status,
    storeUser,
    updateUser,
    adminRoutes,
    handleGenerateEmailCode,
  };
}
