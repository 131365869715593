import React from 'react';
import {Button} from 'antd';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import styled, {css} from 'styled-components';
import HeaderLogoView from './HeaderLogoView';

export interface IHeaderLogoProps {
  headerTitle?: string;
  className?: string;
  handleCollapsedMenu: () => void;
  collapsed: boolean;
  isTableScreen: boolean;
}

const StyledHeaderLogo = styled.div`
  position: relative;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  width: 225px;
  box-shadow: 4px 0 25px 0 ${({theme}) => theme.colors.black};
  background-color: ${({theme}) => theme.colors.third};
`;

const iconColor = css`
  color: ${({theme}) => theme.colors.secondary};
  transition: all 1s;
`;

const IconButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  background-color: ${({theme}) => theme.colors.app} !important;
  border-radius: ${({theme}) => theme.border.baseBorderRadius};
  position: absolute;
  right: -17px;
`;

const StyledRightOutlined = styled(RightOutlined)`
  ${iconColor}
`;
const StyledLeftOutlined = styled(LeftOutlined)`
  ${iconColor}
`;

export default function HeaderLogo({
  headerTitle = 'Company Name',
  handleCollapsedMenu,
  collapsed = false,
  className,
  isTableScreen,
}: IHeaderLogoProps): JSX.Element {
  return (
    <StyledHeaderLogo className={className}>
      <HeaderLogoView headerTitle={headerTitle} />
      <IconButton type="text" onClick={handleCollapsedMenu}>
        {collapsed || isTableScreen ? (
          <StyledRightOutlined />
        ) : (
          <StyledLeftOutlined />
        )}
      </IconButton>
    </StyledHeaderLogo>
  );
}
