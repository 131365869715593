import React from 'react';
import {isPath, isUrl} from '../../../services/helpers';
import {Logo} from '../DataDisplay';
import styled from 'styled-components';
import {Text} from '../Format';

export interface IHeaderLogoViewProps {
  headerTitle: string;
}

const StyledText = styled(Text)`
  color: ${({theme}) => theme.colors.app};
  font-size: 20px;
`;
export default function HeaderLogoView({
  headerTitle,
}: IHeaderLogoViewProps): JSX.Element {
  return (
    <>
      {isUrl(headerTitle) || isPath(headerTitle) ? (
        <Logo url={headerTitle} />
      ) : (
        <>
          <StyledText bold>{headerTitle}</StyledText>
        </>
      )}
    </>
  );
}
