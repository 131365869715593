import {USER} from '../constants';
import {UserModel} from '../../struture';
import {REDUX_STATUS} from '../../services/types';

export interface IUserInitialState {
  user: UserModel | null;
  error: any;
  loading: boolean;
  status: REDUX_STATUS;
}

interface StoreAction {
  type: USER.STORE_USER;
  user: UserModel;
}

interface UpdateAction {
  type: USER.UPDATE_USER;
  user: UserModel;
}

interface LoadingAction {
  type: USER.LOADING_USER;
}

interface ErrorAction extends Pick<IUserInitialState, 'error'> {
  type: USER.ERROR_USER;
}

interface Handlers {
  [USER.STORE_USER]: (
    state: IUserInitialState,
    action: StoreAction,
  ) => IUserInitialState;

  [USER.UPDATE_USER]: (
    state: IUserInitialState,
    action: UpdateAction,
  ) => IUserInitialState;

  [USER.ERROR_USER]: (
    state: IUserInitialState,
    value: ErrorAction,
  ) => IUserInitialState;

  [USER.LOADING_USER]: (
    state: IUserInitialState,
    value: LoadingAction,
  ) => IUserInitialState;
  DEFAULT: (state: IUserInitialState) => IUserInitialState;
}

const initState: IUserInitialState = {
  user: null,
  error: null,
  loading: true,
  status: REDUX_STATUS.IDLE,
};

const handlers: Handlers = {
  [USER.STORE_USER]: (state, {user}) => ({
    ...state,
    ...{
      loading: false,
      status: REDUX_STATUS.SUCCEEDED,
      user,
    },
  }),
  [USER.UPDATE_USER]: (state, {user}) => ({
    ...state,
    ...{
      loading: false,
      status: REDUX_STATUS.SUCCEEDED,
      user,
    },
  }),
  [USER.ERROR_USER]: (state: IUserInitialState, {error}: ErrorAction) => ({
    ...state,
    ...{
      error,
      loading: false,
      status: REDUX_STATUS.FAILED,
    },
  }),
  [USER.LOADING_USER]: (state: IUserInitialState) => ({
    ...state,
    ...{
      loading: true,
      status: REDUX_STATUS.LOADING,
    },
  }),
  DEFAULT: (state: IUserInitialState) => state,
};

type StateStoreActions = StoreAction | LoadingAction | ErrorAction;

export default function User(
  state: IUserInitialState = initState,
  action: StateStoreActions,
): IUserInitialState {
  const handler = handlers[action.type] || handlers.DEFAULT;

  return handler(state, action as any);
}
