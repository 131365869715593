import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import {Spin} from 'antd';

export default withRouter(AppErrorBoundary);

/**
 * @desc AppErrorBoundary component
 * @param children
 * @param rest
 * @returns {*}
 * @constructor
 */

export interface IAppErrorBoundaryProps extends RouteComponentProps<any> {
  children: React.ReactNode | React.ReactNode[];
}

function AppErrorBoundary({children}: IAppErrorBoundaryProps): JSX.Element {
  return (
    <ErrorBoundary fallback={<Spin />} homeUrl="/">
      {children}
    </ErrorBoundary>
  );
}
