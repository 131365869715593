import React, {useCallback, Suspense} from 'react';
import {login, acceptPolicy} from '../../../services/profile';
import {LoginForm} from './Forms';
import {LoginDTO, LoginFormMapper, LoginFormDTO} from '../../../struture';
import AuthLayout from '../Show/AuthLayout';
import {useNotify, useLanguage, useModal} from '../../common/hooks';
import {ApiError, Routes} from '../../../services/types';
import {getContentLanguage} from '../../../services/helpers';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {Spin} from 'antd';

const AcceptModalLazy = React.lazy(() => import('../Accept/Show/AcceptModal'));

export default withRouter(LoginPage);

export interface ILoginPageProps extends RouteComponentProps {}

function LoginPage({history}: ILoginPageProps): JSX.Element {
  const {notifyMessage, notifyErrorMessage} = useNotify();
  const {changeLanguage} = useLanguage();

  const navigateToApp = useCallback(() => {
    history.push(`/${Routes.app}`);
  }, [history]);

  const handleAcceptSubmit = useCallback(async () => {
    const {success} = await acceptPolicy();

    notifyMessage('Accept Terms and Policy successful');

    if (success) {
      return navigateToApp;
    }
  }, [navigateToApp, notifyMessage]);

  const {visible, handleCancel, handleSuccess, handleOnInit} = useModal({
    onSuccess: handleAcceptSubmit,
  });

  const handleSubmit = useCallback(
    async (values: LoginFormDTO) => {
      const loginDTO = LoginFormMapper.toLoginDTO(values);

      const {user} = await login({
        ...loginDTO,
      });

      if (user?.lang) {
        const correctLang = getContentLanguage(user?.lang);

        await changeLanguage(correctLang);
      }

      if (!user?.terms_accepted) {
        return handleOnInit;
      }

      notifyMessage('Login successful');

      return navigateToApp;
    },
    [changeLanguage, handleOnInit, navigateToApp, notifyMessage],
  );

  const notifyError = useCallback(
    (error: ApiError) => {
      notifyErrorMessage(
        'An error occurred during authorization',
        error?.message,
      );
    },
    [notifyErrorMessage],
  );

  return (
    <>
      <AuthLayout isLoginPage title={'Manage the subscriptions'}>
        <LoginForm
          notifyError={notifyError}
          login={LoginFormMapper.toLoginFormDTO(new LoginDTO())}
          onSuccess={handleSubmit as any}
        />
      </AuthLayout>
      <Suspense fallback={<Spin />}>
        <AcceptModalLazy
          visible={visible}
          onSuccess={handleSuccess}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
