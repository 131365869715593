import React, {useCallback} from 'react';
import {PLAN_STATUS, VALUES_PLAN_STATUS} from '../../../services/types';
import {withTranslation, WithTranslation} from 'react-i18next';
import styled from 'styled-components';
import {appAuthUrl} from '../../../services/const';
import {FaExternalLinkAlt} from 'react-icons/fa';
import {Space, Button} from 'antd';

export default withTranslation()(SubscriptionActions);

export interface ISubscriptionActionsProps extends WithTranslation {
  status: VALUES_PLAN_STATUS;
}

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({theme}) => theme.colors.link};
`;

function SubscriptionActions({
  t,
  status,
}: ISubscriptionActionsProps): JSX.Element {
  const navigateToApp = useCallback(() => {
    window.open(appAuthUrl, '_blank');
  }, []);

  return (
    <>
      {status === PLAN_STATUS.NotActivated ||
      status === PLAN_STATUS.In_processing ? null : (
        <StyledButton type="link" onClick={navigateToApp}>
          <Space>
            <FaExternalLinkAlt />
            {t('Go to the system')}
          </Space>
        </StyledButton>
      )}
    </>
  );
}
