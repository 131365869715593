import React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {Menu, Dropdown, Button, Space} from 'antd';
import {UserOutlined, LogoutOutlined} from '@ant-design/icons';
import {Link, withRouter, RouteComponentProps} from 'react-router-dom';
import styled from 'styled-components';
import {getRootKey, isThereContent} from '../../../services/helpers';
import {Text} from '../Format';
import {UserModel} from '../../../struture';
import {Routes} from '../../../services/types';
import {headerButtonTheme} from './headerButtonTheme';

export default withTranslation()(withRouter(HeaderProfileMenu));

export interface IHeaderProfileMenuProps
  extends WithTranslation,
    RouteComponentProps<any> {
  profile: UserModel | null;
  handleLogout: () => Promise<void>;
  isTableScreen: boolean;
}

const StyledUserOutlined = styled(UserOutlined)`
  font-size: 15px;
`;

const StyledLogoutButton = styled(Button)`
  width: 100%;
  height: 100%;
  text-align: left;
  color: ${({theme}) => theme.colors.primary};
  padding: 5px 12px;

  &:hover {
    background-color: transparent !important;
    border-color: transparent !important;
  }
`;

const StyledLink = styled(Link)`
  color: ${({theme}) => theme.colors.primary};
  padding: 5px 12px;
  display: block;
  &:hover {
    color: ${({theme}) => theme.colors.primary};
  }
`;

const StyledMenu = styled(Menu)`
  min-width: 150px;
`;

const StyledMenuItem = styled(Menu.Item)`
  padding: 0;
`;

const StyledButton = styled(Button)`
  border-radius: ${({theme}) => theme.border.baseBorderRadius};

  ${headerButtonTheme}
`;

const StyledFullName = styled(Text)`
  padding: 10px;
`;

function HeaderProfileMenu({
  t,
  profile,
  location,
  handleLogout,
  isTableScreen,
}: IHeaderProfileMenuProps): JSX.Element {
  const key = getRootKey(location.pathname);

  const menu = (
    <StyledMenu data-testid="header__user-menu">
      {isThereContent(profile) && profile?.isFullName && isTableScreen && (
        <StyledFullName>{profile?.shortFullName}</StyledFullName>
      )}
      <StyledMenuItem key="profile">
        <StyledLink to={`/${key}/${Routes.profile}`}>
          <Space>
            <UserOutlined />
            {t('Profile')}
          </Space>
        </StyledLink>
      </StyledMenuItem>
      <StyledMenuItem key="logout">
        <StyledLogoutButton type="text" onClick={handleLogout}>
          <LogoutOutlined /> {t('Logout')}
        </StyledLogoutButton>
      </StyledMenuItem>
    </StyledMenu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <StyledButton
        size="middle"
        type="primary"
        data-testid="user-menu__dropdown-trigger"
        className="ant-dropdown-link">
        <Space size={15}>
          {isThereContent(profile) && profile?.isFullName && !isTableScreen && (
            <Text>{profile?.shortFullName}</Text>
          )}
          <StyledUserOutlined />
        </Space>
      </StyledButton>
    </Dropdown>
  );
}
