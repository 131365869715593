import React from 'react';
import {Col} from 'antd';
import {FlexContainer} from '../Styled';
import CardHeader, {ICardHeaderProps} from './CardHeader';
import CardCollapse, {ICardCollapseProps} from './CardCollapse';
import CardContent, {ICardContentProps} from './CardContent';
import {WithTranslation} from 'react-i18next';
import styled from 'styled-components';

export interface ICardProps extends Partial<ICardHeaderProps> {
  children?: React.ReactNode | React.ReactNode[];
  span?: number;
}

Card.Collapse = ({
  children,
  ...props
}: Omit<ICardCollapseProps, keyof WithTranslation>): JSX.Element => (
  <CardCollapse {...props}>{children}</CardCollapse>
);

Card.Content = (
  props: Omit<ICardContentProps, keyof WithTranslation>,
): JSX.Element => <CardContent {...props} />;

const StyledCol = styled(Col)`
  background-color: ${({theme}) => theme.colors.app};
  border-radius: ${({theme}) => theme.border.baseBorderRadius};
`;

export default function Card({
  status,
  title,
  extra,
  statusPattern,
  children,
  span = 24,
}: ICardProps): JSX.Element {
  return (
    <StyledCol span={span}>
      <FlexContainer flexDirection="column">
        {title && typeof status === 'number' ? (
          <CardHeader
            title={title}
            extra={extra}
            statusPattern={statusPattern}
            status={status}
          />
        ) : null}
        {children || null}
      </FlexContainer>
    </StyledCol>
  );
}
