import React from 'react';
import {Layout} from 'antd';
import classNames from 'classnames';
import {SiderProps} from 'antd/lib/layout';

import './SideBar.less';

const {Sider} = Layout;

export interface ISideBarProps extends SiderProps {
  siderHeader?: React.ReactChild;
  children: React.ReactChild;
}

export default function SideBar({
  onCollapse,
  siderHeader,
  children,
  className = '',
  width,
  trigger,
  ...rest
}: ISideBarProps): JSX.Element {
  return (
    <Sider
      collapsedWidth={0}
      width={width || 225}
      breakpoint="md"
      className="menu-sider"
      collapsible
      trigger={trigger || null}
      onCollapse={onCollapse || (() => {})}
      data-testid="layout-sider"
      {...rest}>
      {siderHeader || null}
      {children}
    </Sider>
  );
}
