import React, {useState, useCallback, useEffect} from 'react';
import {Layout, Row, Space} from 'antd';
import {WithTranslation, withTranslation} from 'react-i18next';
import styled, {css} from 'styled-components';
import {fromBooleanToNumber} from '../../../services/helpers';
import HeaderLogo, {IHeaderLogoProps} from './HeaderLogo';
import {UserModel} from '../../../struture';
import HeaderProfileMenu, {
  IHeaderProfileMenuProps,
} from '../Navigation/HeaderProfileMenu';
import {RouteComponentProps} from 'react-router-dom';

export default withTranslation()(Header);

const {matchMedia} = window;

const tableScreenMediaQuery = matchMedia('(max-width: 767px)');

export interface IAppHeaderProps
  extends WithTranslation,
    Omit<
      IHeaderProfileMenuProps,
      keyof WithTranslation | keyof RouteComponentProps<any> | 'isTableScreen'
    >,
    Pick<IHeaderLogoProps, 'handleCollapsedMenu' | 'collapsed'> {
  profile: UserModel | null;
  handleLogout: () => Promise<void>;
  appTitle: string;
}

const StyledHeader = styled(Layout.Header)`
  height: 64px;
  background-color: ${({theme}) => theme.colors.secondary};
  position: sticky;
  top: 0;
  z-index: 10;
  padding: 0 20px 0 0;
  line-height: 0;

  & .header__menu {
    line-height: 64px;
  }
`;

function Header({
  appTitle,
  handleCollapsedMenu,
  collapsed,
  profile,
  handleLogout,
}: IAppHeaderProps) {
  const [isTableScreen, setIsTableScreen] = useState<boolean>(
    tableScreenMediaQuery?.matches,
  );

  const resizeTableScreen = useCallback(
    ({matches}: MediaQueryListEvent): void => {
      setIsTableScreen(matches);
    },
    [],
  );

  useEffect(() => {
    tableScreenMediaQuery.addEventListener('change', resizeTableScreen);

    return () => {
      tableScreenMediaQuery.removeEventListener('change', resizeTableScreen);
    };
  }, [resizeTableScreen]);

  return (
    <StyledHeader data-testid="layout-header">
      <Row justify={'space-between'}>
        <HeaderLogo
          isTableScreen={isTableScreen}
          headerTitle={appTitle}
          handleCollapsedMenu={handleCollapsedMenu}
          collapsed={collapsed}
        />
        <Space size={13}>
          <HeaderProfileMenu
            isTableScreen={isTableScreen}
            profile={profile}
            handleLogout={handleLogout}
          />
        </Space>
      </Row>
    </StyledHeader>
  );
}
